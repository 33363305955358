/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import get from 'lodash/get';
import { SET_LIST_INDEX, SET_LIST_MODE, SET_REFLEXIVE_LIST_INDEX, UPDATE_LIST_SECTION } from '../types';
import { getNextQuestionSet, getSectionAnswers } from './questions';

export const getAnswersArray = (state, sectionIndex) => {
  const currentBreadcrumbId = get(state, 'questions.activeSubsectionId', '');
  const currentSection = state.questions.sections[currentBreadcrumbId][sectionIndex]
  const pageSeqNumber = get(state, 'questions.currentPageSeqNumber', 1);
  const { completed, answersArray, questionList } = currentSection;
  return {
    completed,
    answersArray,
    questionList,
    pageSeqNumber,
  };
}

export const getListAnswersArray = (state) => {
  const currentBreadcrumbId = get(state, 'questions.activeSubsectionId', '');
      const currentSection = state.questions.sections[currentBreadcrumbId];
      const {
        questionList: questionTest,
      } = getSectionAnswers(currentSection);
      const questionParams = [];
      questionTest.forEach(question => {
        if(question.display_type === 'list') {
          questionParams.push({
            question_id: question.question_id,
            answer: [],
            extra_params: {
              list_completed_flag: true,
            },
          });
        }
        else if(question.display_type === 'questions_group') {
          questionParams.push({
            question_id: question.question_id,
            answer: '',
          });
        }
      });
      return questionParams;
}

const formatPhoneNumber = (phone) => {
  // normalize string and remove all unnecessary characters
  if(phone)
    phone = phone.replace(/[^\d]/g, '');

  if (phone?.length === 10) {
      // reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return phone;
}

const getQuestionDetails = (q) => {
  return {
    question_id: q.question_id, answer: q.answer, transaction_log: q.transaction_log, num_times_answered: q.num_times_answered, lastSubmittedAt: q.lastSubmittedAt,
  }
}

export const addListItem = (sectionIndex, index, qIds, listId, rootListIndex, isNestedList, listActionId, childQLength, rootListId) => {
  return (dispatch, getState) => {
    const state = getState();

    const { uid, questions: { listIndex, rootListIndex: rootListIndexState, nestedListIndex, listMode, nestedListMode } } = state;
    const isEmpty = (value) => (typeof value === 'undefined' || value === null)

    const {
      answersArray,
      questionList,
      pageSeqNumber,
    } = getAnswersArray(state, sectionIndex, true);

    const listQuestion = questionList.filter(question => question.question_id === listId)[0];
    const listCompletedFlag = get(listQuestion, 'child_questions_completed_flag', false);

    let extraParams = {
      list_completed_flag: listCompletedFlag,
    }

    // In case of edit and update, index will be defined
    if (Number.isInteger(index) && index >= 0) {
      extraParams = {
        ...extraParams,
        current_list_answer_index: index,
        edit: true,
      }
    }

    let answer = [];
    let listQs = answersArray.filter(question => (qIds.indexOf(question.question_id) > -1));

    if(qIds.length === 1 && listQs.length>1) {
      let temp = null;
      if(isEmpty(index))
        temp = { question_id: listQs[0].question_id, answer: listQs[listQs.length - 1].answer }
      else
        temp = { question_id: listQs[index].question_id, answer: listQs[index].answer }
    } else {
      listQs.forEach(q => {
          if (q.hasMask && !q.hasEdit) return;
          let temp = getQuestionDetails(q);
          if(q.question_id.match(/phonenumber/i) && !isNaN(q.answer)){
            const formattedNumber = formatPhoneNumber(q.answer)
            if(formattedNumber)
              temp = { ...temp, answer: formattedNumber }
          }
          answer.push(temp);
        })
    }

    if(!isEmpty(rootListIndex) && isEmpty(index)){
        extraParams = {
          ...extraParams,
          current_list_answer_index:  rootListIndex === -1 ? 0 : rootListIndex,
        }
      if(isNestedList){
        extraParams = {
          ...extraParams,
          current_list_answer_index:  nestedListIndex === -1 ? 0 : nestedListIndex,
        }
      }
    }

    let questionParams = [
      {
        question_id: listId,
        answer,
        extra_params: extraParams,
      },
    ];

    let editing = false;
      if(isNestedList && listMode){
        editing= true
      }
    if(!isEmpty(rootListIndex) && isNestedList){
      // const rootParentId = questionList.filter(q => q.display_type === 'list')[0];

      const rootParentId = questionList.filter(q => q.display_type === 'list' && q.question_id === rootListId)[0]
      // const rootParentId = qListTemp.length > 1 ? (qListTemp.filter(q => q.question_id.match(/owner/i))[0]) : qListTemp[0];

      listQs = answersArray.filter(question => (qIds.indexOf(question.question_id) === -1))
      answer = [];

      listQs.forEach(q => {
        const temp = getQuestionDetails(q);
        answer.push(temp);
      })

      answer.push(...questionParams);

      questionParams = [
        {
          question_id: rootParentId.question_id,
          answer,
          extra_params: {
            list_completed_flag: true,
            current_list_answer_index: rootListIndex,
            editing,
          },
        },
      ]
    }

    const reqParams = {
      uid,
      questions: questionParams,
      page_sequence_number: pageSeqNumber,
      index_modify_flag: 1,
      list_question_id: listId,
    }

    dispatch(getNextQuestionSet('/questions', reqParams, {
      isReflexive: true,
      sectionIndex,
      questionId: get(questionList[0],'question_id',''),
      isList: true,
      listActionId,
      ...reqParams,
    }))
  }
}

export const getNextListItemForm = (sectionIndex, listId, rootListIndex, childQLength, isNestedList, rootListId, listActionId) => {
  return (dispatch, getState) => {
    const state = getState();

    const { uid, questions } = state;
    const { currentPageSeqNumber, rootListIndex: rootListIndexState, listMode } = questions;

    const {
      questionList,
    } = getAnswersArray(state, sectionIndex, true);

    let reqParams = {
      uid,
      page_sequence_number: currentPageSeqNumber,
      index_modify_flag: 1,
      next_page_flag: 1,
      list_question_id: listId,
    }

    const listParams = {
      question_id: listId,
      listParams: [],
      current_list_index: childQLength - 1,
    }
    let editing = false;
      if(isNestedList && listMode){
        editing= true
      }
    if(rootListIndexState!== null && rootListIndexState!==undefined && isNestedList){
      // const listQ = questionList.filter(q => q.display_type === 'list')[0];
      // const listParentId = listQ.question_id;

      // const qListTemp = questionList.filter(q => q.display_type === 'list')
      // const listParentId = qListTemp.length > 1 ? (qListTemp.filter(q => q.question_id.match(/owner/i))[0]) : qListTemp[0];
      const listParentId = questionList.filter(q => q.display_type === 'list' && q.question_id === rootListId)[0]

      // let editing = false
      reqParams = {
        list_params: [{
          question_id: listParentId.question_id,
          list_params: [{ ...listParams }],
          current_list_index: rootListIndexState,
          editing,
        }],
        ...reqParams,
      }
    }
    dispatch(getNextQuestionSet('/questions', reqParams, {
      isReflexive: true,
      sectionIndex,
      isListAddAction: true,
      listActionId,
      ...reqParams,
    }));
  }
}

export const submitListAnswers = (sectionIndex, action='', listId) => {
  return (dispatch, getState) => {
    const state = getState();

    const { uid } = state;

    const {
      questionList,
      pageSeqNumber,
    } = getAnswersArray(state, sectionIndex, true);

    // for beneficiaries and contingent beneficiary
    const groupQuestion = questionList.filter(question => question.display_type === 'questions_group')[0];
    const listQuestion = questionList.filter(question => question.display_type === 'list')[0];

    let questionParams = [
      {
        question_id: listId || listQuestion.question_id,
        answer: [],
        extra_params: {
          list_completed_flag: true,
        },
      },
      {
        question_id: groupQuestion?.question_id,
        answer: '',
      },
    ];

    if (action === 'CONFIRM_UPDATE') {
      questionParams = getListAnswersArray(state);
    }

    const extraParams = action ? { action }  : { next_page_flag: 1 }

    let reqParams = {
      uid,
      page_sequence_number: pageSeqNumber,
      questions: questionParams,
      ...extraParams,
    };

    if(action === 'CANCEL_UPDATE'){
      reqParams = {
        uid,
        page_sequence_number: pageSeqNumber,
        ...extraParams,
      }
    }

    dispatch(getNextQuestionSet('/questions', reqParams,
    { sectionIndex,
      sectionId: get(questionList[0],'question_id',''),
      ...reqParams,
    },
    ));

  }
}

export const listSectionUpdates = (sectionIndex, listQIds, index, isCancelUpdate, listId, listActionId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const activeSubsectionId = get(state, 'questions.activeSubsectionId', '');
    dispatch({
      type: UPDATE_LIST_SECTION,
      activeSubsectionId,
      payload: {
        sectionIndex,
        listQIds,
      },
    })

    if(isCancelUpdate){
      const answer = [];
      let extraParams = {
        list_completed_flag: true,
      }

      const { pageSeqNumber, answersArray } = getAnswersArray(state, sectionIndex, true);

      if (Number.isInteger(index) && index >= 0) {
        extraParams = {
          ...extraParams,
          current_list_answer_index: index,
        }
      }

      const listQs = answersArray.filter(question => (listQIds.indexOf(question.question_id) > -1));

      listQs.forEach(q => {
        if (q.hasMask && !q.hasEdit) return;
        const temp = { question_id: q.question_id, answer: q.answer };
        answer.push(temp);
      })

      const questionParams = [
        {
          question_id: listId,
          answer,
          extra_params: extraParams,
        },
      ];

      const reqParams = {
        uid,
        questions: questionParams,
        page_sequence_number: pageSeqNumber,
        index_cancel_flag: 1,
        list_question_id: listId,
      }

      dispatch(getNextQuestionSet('/questions', reqParams, {
        isReflexive: true,
        sectionIndex,
        isList: true,
        listActionId,
        ...reqParams,
      }))
    }
  }
}

export const deleteBeneficiary = (index, sectionIndex, listId, listActionId, rootListIndex=null, isNestedList, rootListId) => {
  return (dispatch, getState) => {
    const state = getState();

    const { uid, questions: { listMode } } = state;

    const {
      questionList,
      pageSeqNumber,
    } = getAnswersArray(state, sectionIndex, true);



  // for beneficiaries and contingent beneficiary
    const listQuestion = questionList.filter(q => q.display_type === 'list' && q.question_id === rootListId)[0]
    const groupQuestion = questionList.filter(question => question.display_type === 'questions_group')[0];
    // const listQuestion = questionList.filter(question => question.display_type === 'list')[0];

    const questionParams = [
      {
        question_id: listId || listQuestion.question_id,
        answer: [],
        extra_params: {
          list_completed_flag: false,
          ...index !== undefined ? { current_list_answer_index: index } : { clear_original_question: true },
        },
      },
      {
        question_id: groupQuestion?.question_id,
        answer: '',
      },
    ];

    let nestedParms = null
    if((rootListIndex || rootListIndex === 0)){
      let editing = false;
      if(isNestedList && listMode){
        editing= true
      }
      nestedParms = [
        {
          question_id: listQuestion.question_id,
          answer: [{
            question_id: listId || listQuestion.question_id,
            answer:[],
            extra_params:{
              list_completed_flag: false,
              current_list_answer_index: index,
            },
          }],
          extra_params:{
            list_completed_flag: false,
            current_list_answer_index: rootListIndex,
            editing,
          },
        },
      ]
    }
    const params = ((rootListIndex === 0) || rootListIndex) ? nestedParms : questionParams;
    const reqParams = {
      uid,
      page_sequence_number: pageSeqNumber,
      list_question_id: listId,
      delete_index_flag: 1,
      questions: params,
    }
    dispatch(getNextQuestionSet('/questions', reqParams, { isReflexive: true, listActionId }));
  }
}

export const setListIndex = (index, rootListIndex) => ({
  type: SET_LIST_INDEX,
  index,
  rootListIndex,
})

export const setReflexiveListIndex = (index, id) => ({
  type: SET_REFLEXIVE_LIST_INDEX,
  index,
  id,
})

export const setRootListIndex = (index) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_ROOT_LIST_INDEX',
      index,
    })
  }
}

export const setNestedListIndex = (index) => ({
  type: 'SET_NESTED_LIST_INDEX',
  index,
})

export const setListMode = (mode) => ({
  type: SET_LIST_MODE,
  mode,
})

export const setListQuestionMap = (listQMap = { listId: '', rootListId: '' }) => ({
  type: 'UPDATE_LIST_RELATION',
  payload: listQMap,
})



export const closeAddListForm = (sectionIndex, listId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const { pageSeqNumber } = getAnswersArray(state, sectionIndex, true);
    const reqParams = {
      uid,
      page_sequence_number: pageSeqNumber,
      list_question_id: listId,
    }
    // adding isReflexive as we need to override the redux data to new questions
    dispatch(getNextQuestionSet('/questions', reqParams,{ sectionIndex, isReflexive:true }))
  }
}
