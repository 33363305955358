/* eslint-disable no-shadow */
import React, { useContext, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { Button as ButtonComponent } from 'crux';
import styled, { withTheme } from 'styled-components';
import Axios from 'axios';
import { connect } from 'react-redux'
import queryString from 'query-string';
import { scrollTop, scrollBottom } from '../utils';
import { SET_ACTION_MESSAGE, API_IN_PROGRESS } from '../redux/types';
import { getUid } from '../util';
import host from '../util/host';
import { axiosInstance } from '../util/axios';
import { updateAnswersArray } from '../redux/actions';
import { getEnableContinueBreadCrumb } from '../redux/selector/validations';
import { IllustrationPDFFetchAction, getNextQuestionSet, handleActionMessage, updateQsResponseData } from '../redux/actions/questions';
import { history } from '../redux/configureStore';
import { ListContext } from '../Components/List/ListContext';
import PDFModal from '../Components/UIElements/Pdf/PDFModal';
import { getFileURL } from '../Components/UIElements/Pdf';
import CustomButton from '../Components/UIElements/CustomButton';
import useCustomButtonPayload from '../Components/UIElements/CustomButton/useCustomButtonPayload';
/**
 * Support for alignment -
 * ['left', 'center', 'right', 'inline' (maybe in future)]
 */
const ButtonWrapper = styled.div`
    display: flex;
    margin: ${({ displayType }) => displayType === 'sub_list_submit' ? '10px 0% 10px 18%' : '10px'};
    margin: ${({ presentationType }) => presentationType === 'illustration_search' ? '10px 4% 25px 18%' : '10px'};
    margin: ${({ presentationType }) => presentationType === 'reged_validation' ? '10px 4% 25px 18%' : '10px'};
    ${({ alignment }) => alignment === 'left' && `
        justify-content: flex-start;
    `}
    ${({ alignment }) => alignment === 'center' && `
        justify-content: center;
        align-content: center;
    `}
    ${({ alignment }) => alignment === 'right' && `
        justify-content: flex-end;
    `}
`

const SpinnerSpan = styled.span`
    display:block;
    margin: 0px 20px;
    align-self: center;
    font-size: ${({ fontSize }) => (fontSize || '20px')};
`
const SpinnerI = styled.i`
    display: inline-block;
`

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    font-size: 14px;
    color: rgb(51, 51, 51);
    /* align-items: center; */
`;

const UploadButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    > * + * {
        margin-left: 20px;
    }
    > label {
        cursor: ${({ disabled }) => disabled ? 'not-allowed': 'pointer'};
    }
`

const UploadLabel = styled.label`
    border: 2px solid #0B465A;
    color: #0B465A;
    border-radius: 4px;
    width: 100px;
    display: flex;
    justify-content: center;
    padding: 1px;
    margin-bottom: 0px;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    :focus-within {
        border: 3px solid #0B465A;
    }
`;

const QuestionText = styled.div`
    ${({ loading }) => loading && 'display: none' }
`;

const ErrorDisplay = styled.div`
    grid-column: span 2;
    ${ ({ message }) => !message.display && 'display: none' };
    text-align: left;
    ${({ message }) => message.error ? 'color: rgb(201, 68, 54)' : 'color: #3cc06f' };
`;

// Button Width setting
const ButtonWidth = {
    XS: 100,
    S: 200,
    M: 260,
    L: 360,
    XL: 560,
}

const Button = ({
    question,
    theme,
    handleEmailSuccessResponse,
    handleEmailErrorResponse,
    _updateAnswersArray,
    sectionIndex,
    _updateLoading,
    listIndex,
    isEditMode,
    enableContinue,
    sectionalData,
    pageSeqNumber,
    activeSubsectionId,
    _updateQuestionsResponse,
    _handleActionMessage,
    handleQuestionsApi,
    listParentId,
    listEditIndex = -1,
    cardIdentifierKeys,
    illustrationPDFFetchAction,
    _updateQsResponseData,
}) => {
    const {
        question_id: questionId,
        question_text: questionText,
        properties,
        display_type: displayType,
        presentation_type: presentationType,
        validations,
        question_description: questionDescription,
        is_editable: isEditable,
        response,
        is_hidden: isHidden,
    } = question;

    if(isHidden) return null;

    const { listState, handleListAction } = useContext(ListContext)

    function recursiveQsIteration (questionList, matchQsRegex, matchedQs = []){
        if(questionList?.questions?.length){
            recursiveQsIteration(questionList?.questions, matchQsRegex, matchedQs);
        }

        if(questionList?.length){
            for(let i = 0 ; i < questionList.length ; i += 1){
                const eachQ = questionList[i]
                if((matchQsRegex.test(eachQ?.question_id) && eachQ?.display_type === 'upload' && eachQ?.question_type === 'button') || (matchQsRegex.test(eachQ?.question_id))){
                    matchedQs.push(eachQ)
                    break;
                } else if(eachQ?.questions?.length){
                    recursiveQsIteration(eachQ?.questions, matchQsRegex, matchedQs);
                }
            }
        }
        return matchedQs;
    }

    // Setting the initial response for the Component field
    useEffect(() => {
        if (question.response && displayType.match(/upload/i)) {
            _updateAnswersArray({
                questionId,
                response,
                sectionIndex,
            })
        }
    }, []);

    // Defining default styling rules for Button
    const defaultStyles = {
        type: 'outlined',
        align: 'center',
        width: `${ButtonWidth.L}px`,
    }

    const [loading, setLoading] = useState(false);
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [pdfObjURL, setPdfObjURL] = useState(false);
    const isCustomErMsg = useRef(false);
    const [message, setMessage] = useState({
        error: false,
        display: response ? `Uploaded file - ${response}` : '',
    });
    const buttonTheme = get(theme, 'config.theme.global.primaryButton');
    const dataActions = get(properties, 'data-action', { type: 'NONE' });
    const buttonStyles = get(properties, 'styles', defaultStyles);
    const uid = getUid(window.location.search)

    useEffect(() => {
        if(displayType === 'upload'){
            const currentSection = sectionalData[activeSubsectionId][sectionIndex];
            const currentSectionQsList = currentSection?.questionList
            const fileUploadRegex = new RegExp('^(BH_UploadFileIllustrationButton)', 'i');
            let fileUploadAnsQ = recursiveQsIteration(currentSectionQsList, fileUploadRegex)

            if(fileUploadAnsQ?.length){
                [fileUploadAnsQ] = fileUploadAnsQ
                if(fileUploadAnsQ?.question_status === 'invalid'){
                    setMessage({
                        error: true,
                        display: fileUploadAnsQ?.question_status_message ? fileUploadAnsQ?.question_status_message : 'We\'re experiencing technical difficulties. Please try after some time!',
                    })
                }
            }
        }
    }, [])

    const resendEmail = async () => {
        try{
            const { REACT_APP_BASE_URL } = host();
            const _axiosInstance = Axios.create({
                baseURL: REACT_APP_BASE_URL,
                method: 'post',
                timeout: 1000 * 1000,
                headers: {
                  'Cache-Control': 'no-cache, no-store, must-revalidate',
                  Pragma: 'no-cache',
                  Expires: '0',
                  Accept: 'application/json',
                },
              });

            const queryParams={
                uid,
            }
            setLoading(true);
            await _axiosInstance.post(
                '/ResendEmail',
                queryParams,
            ).then(() => {
                handleEmailSuccessResponse();
            })
        } catch (err) {
            console.error(err);
            handleEmailErrorResponse();
        }
        setLoading(false);
    }

    const onClickHandler = () => {
        let handleAction = () => {};
        /**
         * Support for data-action types -
         * ['REDIRECT', 'SCROLL_TO_TOP', 'SCROLL_TO_BOTTOM', 'REFRESH']
         */
        switch(dataActions.type) {
            case 'REDIRECT':
                handleAction = () => {
                    window.location.href = dataActions.link || '';
                };
                break;
            case 'SCROLL_TO_TOP':
                handleAction = scrollTop;
                break;
            case 'SCROLL_TO_BOTTOM':
                handleAction = scrollBottom;
                break;
            case 'RESEND_EMAIL':
                handleAction = () => {
                    resendEmail();
                };
                break;
            case 'REFRESH':
                handleAction = () => {
                    window.location.reload();
                };
                break;
            case 'NONE':
            default:
                break;
        }
        handleAction();
    }

    if(displayType === 'sub_list_submit'){
        const customBtnHookProps = {
            activeSubsectionId,
            displayType,
            listIndex,
            pageSeqNumber,
            presentationType,
            questionId,
            sectionalData,
            sectionIndex,
            validations
        }

        const { queryParams } = useCustomButtonPayload(customBtnHookProps)

        const customButtonProps = {
            activeSubsectionId,
            buttonClickHandler: () => {
                handleListAction({
                    ...listState,
                    questionId,
                    subListAction: 'sub_list_submit',
                });
                handleQuestionsApi(queryParams, { sectionIndex, isReflexive: true })
            },
            buttonStyles: {
                ...buttonStyles,
                ...buttonTheme
            },
            isDisabled: !enableContinue || loading || !isEditable,
            loading,
            pageSeqNumber,
            presentationType,
            question,
            sectionalData,
            sectionIndex
        }

        return (
            <CustomButton {...customButtonProps} />
        );
    }

    if(presentationType === 'fetch_illustration_pdf'){
        const _buttonStyles = {
            type: 'outlined',
            align: 'right',
            width: `${ButtonWidth.S}px`,
        }

        const customBtnHookProps = {
            activeSubsectionId,
            pageSeqNumber,
            presentationType,
            questionId,
            sectionalData,
            sectionIndex,
            cardIdentifierKeys
        }

        const { queryParams, showIllustrationPDF, fetchedIllustrations } = useCustomButtonPayload(customBtnHookProps)

        let fileUrl;
        const curButtonCaseId = cardIdentifierKeys?.case_id
        const illusSearchEnabled = true

        const fetchPDFIllustrations = async () => {
            _updateLoading(true);
            const fetchPDFResp = await axiosInstance.post('/questions', queryParams)
            _updateLoading(false);

            const sectionData = fetchPDFResp?.data?.response?.data?.questionnarie?.questions?.questions[sectionIndex]

            const accordionPQRegex = new RegExp('^(BH_IllustrationList)', 'i');
            const accordionAnsQ = recursiveQsIteration(sectionData, accordionPQRegex)
            if(accordionAnsQ?.length){
                const accordionCardsResp = get(accordionAnsQ[0], 'accordion_cards', []);
                const curAnseredAcc = accordionCardsResp.filter((eachAcc) => eachAcc.case_id === curButtonCaseId)[0]
                if(curAnseredAcc){
                    const illustrationPDFButtonQ = curAnseredAcc.card_questions.filter((eachCardQ) => eachCardQ.presentation_type === 'fetch_illustration_pdf')[0]
                    let queryParams = {
                        case_id: curButtonCaseId,
                    }
                    if(illustrationPDFButtonQ?.response){
                        const documentURL = illustrationPDFButtonQ.response;
                        const queryStartIndex = documentURL.indexOf('?');
                        queryParams = {
                            ...queryParams,
                            url: documentURL,
                            ...queryString.parse(documentURL.substring(queryStartIndex)),
                        }
                        try {
                            _updateLoading(true);
                            const illustrationPDFData = await axiosInstance.post('/documents', queryParams);

                            setShowPDFModal(true);
                            fileUrl = getFileURL(illustrationPDFData.data)
                            illustrationPDFFetchAction(cardIdentifierKeys?.case_id, fileUrl)

                            _updateLoading(false);
                        } catch (err) {
                            _updateLoading(false);
                            console.error(err);
                        }
                    }
                }
            }
        }

        const displayIllustrationPDF = showPDFModal || pdfObjURL || showIllustrationPDF?.is_clicked

        const customButtonProps = {
            activeSubsectionId,
            buttonClickHandler: () => {
                if(fetchedIllustrations?.[curButtonCaseId]){
                    setPdfObjURL(fetchedIllustrations?.[curButtonCaseId])
                    setShowPDFModal(true)
                }else{
                    fetchPDFIllustrations()
                }
            },
            buttonStyles: {
                ..._buttonStyles,
                ...buttonTheme
            },
            isDisabled: !illusSearchEnabled || loading || !isEditable,
            loading,
            pageSeqNumber,
            presentationType,
            question,
            sectionalData,
            sectionIndex
        }

        return (
            <>
                {displayIllustrationPDF && <PDFModal fileUrl={ pdfObjURL || showIllustrationPDF?.dataBuffer } showPDFModal={ showPDFModal } setShowPDFModal={ setShowPDFModal } />}
                <CustomButton {...customButtonProps} />
            </>
        );
    }

    if(presentationType === 'reged_validation'){
        const _buttonStyles = {
            type: 'outlined',
            align: 'right',
            width: `${ButtonWidth.L}px`,
        }

        const customBtnHookProps = {
            activeSubsectionId,
            pageSeqNumber,
            presentationType,
            questionId,
            sectionalData,
            sectionIndex,
        }

        const { queryParams } = useCustomButtonPayload(customBtnHookProps)

        const customButtonProps = {
            activeSubsectionId,
            buttonClickHandler: () => handleQuestionsApi(queryParams),
            buttonStyles: {
                ..._buttonStyles,
                ...buttonTheme
            },
            isDisabled: loading || !isEditable,
            loading,
            pageSeqNumber,
            presentationType,
            question,
            sectionalData,
            sectionIndex
        }

        return (
            <CustomButton {...customButtonProps} />
        );
    }

    if(presentationType === 'illustration_search'){
        const _buttonStyles = {
            type: 'outlined',
            align: 'right',
            width: `${ButtonWidth.S}px`,
        }

        const customBtnHookProps = {
            activeSubsectionId,
            pageSeqNumber,
            presentationType,
            questionId,
            sectionalData,
            sectionIndex,
            validations
        }

        const { queryParams, illusSearchEnabled } = useCustomButtonPayload(customBtnHookProps)

        const customButtonProps = {
            activeSubsectionId,
            buttonClickHandler: () => {
                // setLoading(true)
                handleListAction({
                    ...listState,
                    questionId,
                    subListAction: 'illustration_search_button_click',
                });
                handleQuestionsApi(queryParams)
                // setLoading(false)
            },
            buttonStyles: {
                ..._buttonStyles,
                ...buttonTheme
            },
            isDisabled: !illusSearchEnabled || loading || !isEditable,
            loading,
            pageSeqNumber,
            presentationType,
            question,
            sectionalData,
            sectionIndex
        }

        return (
            <CustomButton {...customButtonProps} />
        );
    }

    if (displayType === 'upload') {

        const upload = async (data) => {
                history.push({
                    pathname: `/questions/${activeSubsectionId}`,
                    search: `?uid=${uid}&pdf_upload=1`,
                })
                return axiosInstance.post(
                    '/questions?pdf_upload=1',
                    data,
                );
        }

        const handleUploadAction = (e) => {
            _updateLoading(true);
            setLoading(true);
            try {
                const { name: fileName, type: fileType, size: fileSize } = e.target.files[0]

                if (fileType !== validations?.upload_file_type) {
                    setMessage({
                        error: true,
                        display: `Only file type ${validations.upload_file_type} is allowed`,
                    })
                    _updateLoading(false);
                    setLoading(false);
                    return;
                }

                if (fileSize > (validations?.upload_max_file_size || 5) * 10**6) {
                    setMessage({
                        error: true,
                        display: `Selected file size - ${(fileSize/(10 ** 6)).toFixed(2)}, Maximum allowed size is ${validations?.upload_max_file_size || 5} MB`,
                    })
                    _updateLoading(false);
                    setLoading(false);
                    return;
                }
                setMessage({
                    error: false,
                    display: '',
                })

                const reader = new FileReader();

                reader.readAsDataURL(e.target.files[0]);

                reader.onloadend = async () => {
                    try {
                        const b64 = reader.result.replace(/^data:.+;base64,/, '');
                        const formData = new FormData();
                        formData.append('response', b64);
                        formData.append('file_upload_flag', 1);
                        formData.append('uid', uid);
                        formData.append('page_sequence_number', pageSeqNumber);
                        if(isEditMode && (listIndex || listIndex === 0)) formData.append('list_index', listIndex);
                        formData.append('questions',JSON.stringify([{
                            question_id: questionId,
                            answer: fileName,
                        }]));

                        const uploadResponse = await upload(formData);
                        let sectionData = uploadResponse?.data?.response?.data?.questionnarie?.questions
                        if(listParentId){
                            const uploadActionRegex = new RegExp(`^(${listParentId})`, 'i');
                            let listAnsweredQ = recursiveQsIteration(sectionData, uploadActionRegex)
                            if(listAnsweredQ?.length) {
                                [listAnsweredQ] = listAnsweredQ
                                const { original_questions: originalQs } = listAnsweredQ
                                if(!listAnsweredQ.child_questions_completed_flag){
                                    sectionData = originalQs
                                }else if(listAnsweredQ?.questions?.length && listAnsweredQ?.questions[listEditIndex]){
                                    sectionData = listAnsweredQ?.questions[listEditIndex]
                                }
                            }
                         }

                        const uploadBtnIds = ['BH_UploadFileIllustrationButton', 'BH_Comparative_Form', 'BH_Comparative_Form_Owner'];
                        const uploadActionRegex = new RegExp(`^(${uploadBtnIds.join('|')})`, 'i');
                        const uploadQFdbck = recursiveQsIteration(sectionData, uploadActionRegex)
                        _updateQsResponseData(uploadResponse)
                        if(uploadQFdbck?.length && uploadQFdbck[0]?.question_status === 'invalid'){
                            isCustomErMsg.current = true
                            const errorMsg = uploadQFdbck[0].question_status_message
                            throw new Error(errorMsg)
                        }
                        history.push({
                            pathname: `/questions/${activeSubsectionId}`,
                            search: `?uid=${uid}`,
                        })
                        setMessage({
                            error: false,
                            display: `File - ${fileName} has been uploaded successfully`,
                        })

                        // _updateAnswersArray({
                        //         questionId,
                        //         response: fileName || response,
                        //         // hasEdit,
                        //         // hasMask,
                        //         sectionIndex,
                        //         // preventReflexiveCall,
                        //         // isError: false,
                        //         // ignoreChildQuestionsOn,
                        //         // propQuestion: props.question,
                        //         // listParentId: props.listParentId,
                        //         // listIndex: props.listIndex,
                        //         // auditRequired,
                        // })

                    } catch (err) {
                        console.error(err);
                        history.push({
                            pathname: `/questions/${activeSubsectionId}`,
                            search: `?uid=${uid}`,
                        })
                        setMessage({
                            error: true,
                            display: (isCustomErMsg.current && !err?.response?.status) ? err.message : 'We\'re experiencing technical difficulties. Please try after some time!',
                        })
                    }
                    _updateLoading(false);
                    setLoading(false);
                };
            } catch (err) {
                console.error(err);
                _updateLoading(false);
                setLoading(false);
            }
        }

        return (
            <UploadContainer>
                <div>{ questionDescription } </div>
                <UploadButtonWrapper disabled={ loading || !isEditable || (listState?.action && !listParentId) }>
                    <UploadLabel htmlFor={ questionId } disabled={ loading || !isEditable }>
                        <input
                            key={ loading }
                            disabled={ loading || !isEditable || (listState?.action && !listParentId) }
                            style={ { height: '0px', width: '0px' } }
                            type="file"
                            aria-label={ questionDescription }
                            id={ questionId }
                            name={ questionId }
                            accept={ validations?.upload_file_type }
                            onChange={ handleUploadAction }
                        />
                        <QuestionText loading={ loading }>{(response ? 'Update': questionText) }</QuestionText>
                        {loading && <SpinnerSpan fontSize="14px"><SpinnerI className="fa fa-circle-o-notch fa-1x fa-spin" aria-hidden="true"/></SpinnerSpan>}
                    </UploadLabel>
                    <ErrorDisplay message= { message } > {message.display} </ErrorDisplay>
                </UploadButtonWrapper>
            </UploadContainer>
        );
    }
    return (
        <ButtonWrapper alignment={ buttonStyles.align }>
            <ButtonComponent
                label={ questionText }
                handleButtonClick={ onClickHandler }
                { ...buttonStyles }
                { ...buttonTheme }
            />
            {loading && <SpinnerSpan><SpinnerI className="fa fa-circle-o-notch fa-1x fa-spin" aria-hidden="true"/></SpinnerSpan> }
        </ButtonWrapper>
    );
}

 const mapStateToProps = (state, props) => {
    const { sectionIndex } = props;
    const id = get(state, 'questions.activeSubsectionId', '');
    const pageId = window.location.pathname.split('/')[1] || '';
    const isSubListSubmitBtn = true
    const pageSeqNumber = get(state, 'questions.currentPageSeqNumber', -1);
    const dependantSelectiveFieldIds = get(props, 'question.validations.dependant_selective_field_ids', []);
    const enableContinue = getEnableContinueBreadCrumb(state, id, undefined, pageId, sectionIndex, dependantSelectiveFieldIds, isSubListSubmitBtn);
    return {
        pageSeqNumber,
        enableContinue,
        activeSubsectionId: id,
        actionMessage: state?.questions?.actionMessage,
        sectionalData: state.questions.sections,
        listEditIndex: state?.questions?.listEditIndex,
    }
 }

 const mapDispatchToProps = (dispatch, props) => {
    const { sectionIndex , activeSubsectionId } = props;
    return {
        handleEmailErrorResponse  : (errorMessage='We\'re experiencing technical difficulties. Please try after some time!') => {
            dispatch({
            type: SET_ACTION_MESSAGE,
            payload: {
                actionMessage: {
                    type: 'error',
                    message: errorMessage,
                },
            },
        });
        },
        handleEmailSuccessResponse  : (successMessage='Email Sent Successfully') => {
            dispatch({
                type: SET_ACTION_MESSAGE,
                payload: {
                    actionMessage: {
                        type: 'success',
                        message: successMessage,
                    },
                },
            });
        },
        handleQuestionsApi: (formData, reqParams = {}) => { dispatch(getNextQuestionSet('/questions', formData, reqParams)) },
        _updateQsResponseData: (response) => {
            dispatch(updateQsResponseData(response, { sectionIndex, isReflexive: false }))
        },
        _updateAnswersArray: (data) => { dispatch(updateAnswersArray(data)) },
        _updateLoading     : (data) => { dispatch({ type: API_IN_PROGRESS, payload: { isLoading: data } })},
        _updateQuestionsResponse: (currentStatus, response, params) => {
            dispatch({
                type: `GET_${currentStatus}_SUCCESS`,
                payload: get(response, 'data.response', null),
                params,
            });
        },
        _handleActionMessage: (response) => {
            dispatch(handleActionMessage(response));
        },
        illustrationPDFFetchAction: (caseId, fileUrl) => {
            dispatch(IllustrationPDFFetchAction({ sectionIndex, caseId, activeSubsectionId, dataBuffer: fileUrl }))
        },
    }
  }

export default withTheme(connect(mapStateToProps,mapDispatchToProps)(Button));