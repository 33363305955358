/* eslint no-unused-vars : "off" */
/* eslint-disable no-useless-escape */
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { axiosInstance } from '../../util/axios';
import {
	  UPDATE_QUESTION_ANSWER,
	  UPDATE_DATA_SELECTOR_OPTIONS,
    GET_NEW_QUESTION_START,
    SET_CURRENT_BREADCRUMB,
	  GET_QUESTION_FAILURE,
	  SET_ACTION_MESSAGE,
	  TOGGLE_ESIGN_BUTTON,
	  SET_PDF_CONFIGS,
	  SET_POPUP_DETAILS,
	  UPDATE_LAST_FOCUSED_ELEMENT_ID,
	  SET_REFLEXIVE_LIST_INDEX,
	  UPDATE_QUESTION_ANSWER_TIME_STAMP,
	  TOGGLE_SIGNATURE_CONTINUE,
} from '../types';
import { setUid, setCJEventId } from './configActions';
import router, { pageRouter } from '../../util/router';
import { getMaskedResponses, getUTCTimestamp, getFormatDate } from '../../utils';
import { getRefreshToken, getJWTAuthToken } from './authHandler';
import prepareQuestionsPayload from '../../util/questionFormatter';
import { mockAlertPopup } from '../../mockResponse';
import { MODALTYPES } from '../../cruxComponents/utils';
import HELPER from '../reducers/helper';
import { getStartTime } from '../../util';

export const setPopupDetails = (payload) => ({
	type: SET_POPUP_DETAILS,
	payload,
});

// Handles display of Error Action Message, being sent from the backend
export const handleActionMessage = (response) => {
	return (dispatch) => {
		let errorMessage = '';
		switch(response.status) {
			case 200: {
				errorMessage = get(response, 'data.errors.userMessage');
				break;
			}
			default: {
				errorMessage = get(response, 'response.data.errors');
				// Fetch userMessage message from errors object if errors is not of type string.
				if (typeof errorMessage !== 'string') {
					errorMessage = get(response, 'response.data.errors.userMessage');
				}
				break;
			}
		}
		// Sets error message on network disconnections
		const hasNetworkRetry = get(response, 'config.axios-retry.retryCount', 0);
		if (!errorMessage && hasNetworkRetry > 0) {
			errorMessage = 'Your internet connection is unstable.';

			const responseCode = get(response, 'response.status') || response.status;
			const isServerError = Number(responseCode)%500 <= 11;
			if (isServerError) {
				errorMessage = 'We\'re experiencing technical difficulties. Please try after some time!';
			}

			// Forcefully displays the popup on network disconnections
			dispatch(setPopupDetails({
				question: mockAlertPopup(errorMessage),
				overlayBgColor: 'rgba(255,255,255,0.5)',
				show: true,
				force: true,
				handleClick: () => {
					const url =  get(response, 'config.url', '');
					const data = get(response, 'config.data', '');
					if (url) {
						// eslint-disable-next-line no-use-before-define
						dispatch(getNextQuestionSet(url, JSON.parse(data), {}));
					  }
				},
				hasBorder: true,
				showModalClose: false,
				label: 'Retry',
				type: MODALTYPES.retry,
			}));
		}
		dispatch({
			type: GET_QUESTION_FAILURE,
		});
		dispatch({
			type: SET_ACTION_MESSAGE,
			payload: {
				actionMessage: {
					type: 'error',
					message: errorMessage || 'Some Error Occured!',
				},
			},
		});
	}
}

const getCookieData = (name) => {
	const nameEQ = `${name  }=`;
    const ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i+=1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const getNextQuestionSet = (
	url,
	formData,
	params = {
		isReflexive: false,
		sectionIndex: 0,
		validate: false,
		isListAddAction: false,
		reload: false,
		actionProps: {},
		breadcrumbNavigation: false,
		actionMessage: { type: '', message: '' },
		maskedQArray: [],
		isNetworkResponse: true,
		listActionId: undefined,
	},
) => {
	return (dispatch, getState) => {
		const state = getState();
		const queryUid = get(state, 'router.location.query.uid', '');
		const lastFocusedElementId = get(state, 'questions.lastFocusedElementId', '');
		const cje = get(state, 'config.cje', '')
		let updatedFormData = queryUid
		  ? { ...formData, uid: queryUid, cje }
		  : { ...formData };

		const ipAddress = get(state, 'meta.ipAddress', '');
		// const localeTime = getStartTime().stringDate.split(' ').splice(1);
		// localeTime[0] = String(getStartTime().month).padStart(2, '0')
		updatedFormData = { ...updatedFormData, ipAddress,  user_local_time: getStartTime()?.stringDate?.match(/([\+-][0-9]+)/)[1] };

		dispatch({
			type: GET_NEW_QUESTION_START,
			updatedFormData,
			params,
		});

		const listActionIds = ['list-cancel-btn', 'list-delete-btn', 'list-add-btn']
  		const actionIdRegex = new RegExp(`^(${listActionIds.join('|')})`, 'i');
		if (!params.isReflexive || params.listActionId || (params.isReflexive && !params.listActionId && actionIdRegex.test(lastFocusedElementId)))
			dispatch({
				type: UPDATE_LAST_FOCUSED_ELEMENT_ID,
				payload: {
					id: params.listActionId || 'btn-continue',
				},
			})
		axiosInstance
			.post(url, updatedFormData)
			.then((response) => {
				// identity check, if response is valid, then only show the requested page,
				// else /inactivesession response is handled, from server side.
				const identity = get(response, 'config._identity', 'invalid');
				if (identity === 'valid') {
					// retry logic
					const retry = get(response, 'config._retry', false);
					if (retry) {
						const retryInterval = get(response, 'config._retryInterval', 0);
						setTimeout(() => {
							dispatch(getNextQuestionSet('/questions', {}, {}));
						}, retryInterval * 1000);
					}

					const uid = get(
						response,
						'data.response.other_params.uid',
						'',
					);

					const cjEventId = get (
						response,
						'data.response.other_params.cjevent_id',
					) || getCookieData('cje');

					const currentStatus = get(
						response,
						'data.response.other_params.current_status',
						'',
					).toUpperCase();

					if (currentStatus) {
						router(dispatch, uid, cjEventId, response);
						dispatch(setCJEventId(cjEventId ));
						dispatch(setUid(uid));
						dispatch({
							type: `GET_${currentStatus}_SUCCESS`,
							payload: get(response, 'data.response', null),
							params,
						});
					} else {
						dispatch(handleActionMessage(response));
					}
					if (!isEmpty(params.actionProps)) {
						dispatch({
							type: SET_ACTION_MESSAGE,
							payload: {
								actionMessage: {
									type: 'success',
									message: get(params, 'actionProps.success.message', null),
								},
							},
						})
					}
				}
			})
			.catch(async(error) => {
				console.error(error);
				const refreshRetry = get(error, 'response.config._retry', false);
				if (refreshRetry) {
					const originalRequest = error.config;
					const accessToken = await dispatch(getRefreshToken());
					if (accessToken) {
						axiosInstance
						.post(originalRequest.url, JSON.parse(originalRequest.data))
						.then((response) => {
							// identity check, if response is valid, then only show the requested page,
							// else /inactivesession response is handled, from server side.
							const identity = get(response, 'config._identity', 'invalid');
							if (identity === 'valid') {
								// retry logic
								const retry = get(response, 'config._retry', false);
								if (retry) {
									const retryInterval = get(response, 'config._retryInterval', 0);
									setTimeout(() => {
										dispatch(getNextQuestionSet('/questions', {}, {}));
									}, retryInterval * 1000);
								}

								const uid = get(
									response,
									'data.response.other_params.uid',
									'',
								);

								const cjEventId = get (
									response,
									'data.response.other_params.cjevent_id',
								)

								const currentStatus = get(
									response,
									'data.response.other_params.current_status',
									'',
								).toUpperCase();
								router(dispatch, uid, cjEventId, response);
								dispatch(setCJEventId(cjEventId))
								dispatch(setUid(uid));
								dispatch({
									type: `GET_${currentStatus}_SUCCESS`,
									payload: get(response, 'data.response', null),
									params,
								});
							}
						}).catch((err) => {
							console.error(err);
				            const errText = get(err, 'response.statusText', '');
							if(errText === '"Unauthorized"'){
								pageRouter(dispatch, 'authError');
								dispatch({
									type: SET_ACTION_MESSAGE,
									payload: {
										actionMessage: {
											type: 'error',
											message: `${errText}.You cannot access the application.`,
										},
									},
								})
							}
						})
					}
				} else {
					dispatch(handleActionMessage(error));
				}
			})
	};
}

export const updateQsResponseData = (response, params) => {
	return (dispatch) => {
		const uid = get(
			response,
			'data.response.other_params.uid',
			'',
		);

		const cjEventId = get (
			response,
			'data.response.other_params.cjevent_id',
		) || getCookieData('cje');

		const currentStatus = get(
			response,
			'data.response.other_params.current_status',
			'',
		).toUpperCase();

		if (currentStatus) {
			router(dispatch, uid, cjEventId, response);
			dispatch(setCJEventId(cjEventId ));
			dispatch(setUid(uid));
			dispatch({
				type: `GET_${currentStatus}_SUCCESS`,
				payload: get(response, 'data.response', null),
				params,
			});
		} else {
			dispatch(handleActionMessage(response));
		}
	}
}

export const getMockQuestionSet = (response, params = {}) => {
	return (dispatch) => {
		const currentStatus = get(
			response,
			'data.response.other_params.current_status',
			'',
		).toUpperCase();
		if (params.isNetworkResponse !== false)
			router(dispatch, null, false, response);
		dispatch({
			type: `GET_${currentStatus}_SUCCESS`,
			payload: get(response, 'data.response', null),
			params,
		});
	}
}

export const getSectionAnswers = (currentBreadcrumb)=>{
	const answersArray = []
	const answerToBeIncluded = (answer, exceptionQList) => {
		let isDateCleared = false
		if(exceptionQList.length && exceptionQList?.includes(answer.question_id)){
			isDateCleared = true
		}
		const allowEmptyResponses = (currentBreadcrumb[0].requiredSchema?.includes('BH_TotalAllocations') && true) ||false
		return (answer.answer || answer.answer === 0 || allowEmptyResponses || isDateCleared)
	}
	currentBreadcrumb.forEach(section => {
		const dateClearedQsList = section.questionList.filter(eachQ => eachQ.question_type === 'date' && eachQ?.isDateCleared).map((q) => q.question_id)
		section.answersArray.forEach(
		answer => answerToBeIncluded(answer, dateClearedQsList) && answersArray.push(answer))
	})
	let questionList = currentBreadcrumb.reduce((acc, section)=> {
		return [...acc, ...section.questionList]
	}, [])
	questionList = questionList.filter(question => question.presentation_type !== 'review')
	return {
	  answersArray,
	  questionList,
	}
}

export const getAnsweredListQuestion = (currentBreadcrumb) => {
	const answeredListQuestion = currentBreadcrumb.reduce((acc, section)=> {
		return [...acc, ...section.answeredListQuestion]
	}, [])
	return answeredListQuestion;
}

const formatAuthAnswers = (answersArray) => {
	const reducer = (accumulator, currentValue) => {
		accumulator[currentValue.question_id] = currentValue.answer;
		return accumulator;
	  }
	return answersArray.reduce(reducer, {});
}

export const submitAuthQuestion = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { questions } = state;
		const { activeSubsectionId } = questions;
		const currentSection = questions.sections[activeSubsectionId];
		const  { answersArray } = getSectionAnswers(currentSection);

		const destinationURL = get(state, 'auth.destinationURL', '/');
		const loginURL = get(state, 'auth.login.loginURL', '/auth');
		let reqParams;
		if (!isEmpty(answersArray)) {
			reqParams = formatAuthAnswers(answersArray);
		}
		dispatch(
			getJWTAuthToken(loginURL, reqParams, {
				redirectTo: destinationURL,
			}),
		);
	}
}

const insertMissingAnswers = (
	answersArray,
	questionList,
	isReflexive,
) => {
	return questionList.reduce((acc, question) => {
		const alreadyAnsweredQuestionObject = answersArray.filter(
			(item) => item.question_id === question.question_id,
		);
		if (question.display_type === 'breadcrumb' || (question.display_type === 'list' && question.submission_type === 'all')) {
			return acc;
		}
		if (alreadyAnsweredQuestionObject.length) {
			acc.push(...alreadyAnsweredQuestionObject);
			return acc;
		}
		if (question.question_id && question.display_type) {
			if (!isReflexive)
				acc.push({
					question_id: question.question_id,
					answer: '',
				});
		}
		return acc;
	}, []);
};

const appendListCompletionFlag = (questions, answeredListQuestion) => {
	answeredListQuestion.map((qId) => {
		if(!questions.find(question => question.question_id=== qId)) {
			questions.push({
				question_id: qId,
				answer:[],
			})
		}
		return null;
	})
	const appended = questions.map(question=> {
		if(answeredListQuestion.indexOf(question.question_id)>-1){
			return {
				...question,
				answer:[],
				extra_params: {
          			list_completed_flag: true,
        		},
			}
		}
		return question;
	})
	return appended;
}

const getNListAnswers = (currentSubsection) => {
	const { answersArray } = currentSubsection;
	return answersArray.filter(a => a.question_id.match(/_\$ureify_/));
}

const prepareListQsAnswersObj = (listQs) => {
	const extraParams = {
		list_completed_flag: false,
	};

	const { listParentId, listIndex } = listQs[0];
	const answer = [];
	if (Number.isInteger(listIndex) && listIndex >= 0) {
		extraParams.current_list_answer_index = listIndex;
	}
	listQs.forEach(q => {
		const temp = { question_id: q.question_id, answer: q.answer };
		answer.push(temp);
	})
	return {
			question_id: listParentId,
			answer,
			extra_params: extraParams,
		}
}
	/** fetching answers for answered list items recursively on nested Qs */
	// function _isArray(element) {
	// 	if (typeof element === 'object' && element?.length) {
	// 		return true;
	// 	}
	// 		return false;

	// }

	// function listArrayAnswersObjMap(listItems, curListIdx) {
	// 	let listArray = listItems;
	// 	listArray = listItems.map((listItem, i) => {
	// 		if (_isArray(listItem)) {
	// 			listArray[i] = listArrayAnswersObjMap(listItem, i);
	// 		} else {
	// 			return {
	// 				question_id: listItem?.question_id,
	// 				answer: listItem?.response,
	// 			}
	// 		}
	// 		return listItem
	// 	});
	// 	return listArray;
	// }

const formatListQuestions = (questions, currentSecQList) => {
	const listQs = questions.filter(q => q.listParentId);
	let otherQs = questions.filter(q => !q.listParentId);
	const otherQIds = otherQs.map(q => q.question_id);

	const answeredListQs = currentSecQList.filter(eachQ => otherQIds.includes(eachQ.question_id) && eachQ.display_type === 'list');
	const answeredListQsObj = answeredListQs.reduce((acc, listQ) => {
		return { ...acc, [listQ.question_id]: listQ }
	}, {});

	otherQs = otherQs.map(q => {
		const answeredListQ = get(answeredListQsObj, `${q?.question_id}`, null);
		if(answeredListQ && answeredListQ?.child_questions_completed_flag){
			return {
				question_id: q.question_id,
				answer: [],
				extra_params: {
					'list_completed_flag': true,
				},
			}
		}
		return q;
	})

	if (listQs && listQs.length) {
		const listIds = listQs.map(listQ => listQ.listParentId);
		const uniqListIds = [...new Set(listIds)];
		const isMultiListAction = uniqListIds.length > 1
		let listQsAnswersArray = [] ; const formattedListQs = []; let listQsAnswersObj;

		if(isMultiListAction){
			listQsAnswersArray = uniqListIds.reduce((acc, listId) => {
				const listAnsweredQs = listQs.filter((listQ) => listQ.listParentId === listId)
				listQsAnswersObj = prepareListQsAnswersObj(listAnsweredQs)
				acc.push(listQsAnswersObj)
				return acc;
			},[])
			return [...listQsAnswersArray, ...otherQs];
		}

		listQsAnswersObj = prepareListQsAnswersObj(listQs)
		formattedListQs.push(listQsAnswersObj)

		if(otherQs.length){
			return [...formattedListQs, ...otherQs];
		}

		return [...formattedListQs];
	}
	return questions;
}

/**
 * @description Prepares payload for auditing timestamps and browser specific info.
 * @param {*} meta
 * @param {*} currentSubsection
 */
const prepareAuditPayload = (meta, currentSubsection) => {
	const ipAddress = get(meta, 'ipAddress', '');
	const audit = get(currentSubsection, 'audit', []);
	const currentUTCTimestamp = getUTCTimestamp();
	const onloadTimestamp = get(currentSubsection, 'onload_timestamp', '');
	const userAgent = `${get(meta, 'userAgent.name', '')} v${get(meta, 'userAgent.version', '')}`;

	return {
		browser_info: userAgent,
		ip_address: ipAddress,
		onload_timestamp: onloadTimestamp,
		onsubmit_timestamp: currentUTCTimestamp,
		audit,
	}
}

export const submitSectionQuestion = (
	sectionIndex,
	otherParams = {
		isList: false,
		isReflexive: false,
		listIndex: null,
		sectionIndex: 0,
		validate: false,
		actionProps: {},
		removeIndex: {},
		ipAddress:'',
		questionId: '',
	},
	action= '',
	url = '/questions',
) => {
  return (dispatch, getState) => {
    const state = getState();
		const { questions, meta, uid } = state;
		const { activeSubsectionId } = questions;
		const pageSeqNumber = get(
			questions,
			'currentPageSeqNumber',
			1,
		);
		const signaturePages = ['signature', 'bh_hippa_authorization'];
		const currentSection =
			questions.sections[activeSubsectionId];
		const currentSubsection = currentSection[sectionIndex];
		const { completed, questionList: currentQuestionList } = currentSubsection;
		const hasModal = get(currentSubsection, 'otherParams.has_modal', false);

		let allQuestions = [];

		const  { answersArray, questionList } = getSectionAnswers(currentSection);

		if (otherParams.isReflexive || otherParams.validate) {
			allQuestions =  answersArray;
		} else {
			allQuestions = [
				...insertMissingAnswers(answersArray, questionList),
				...getNListAnswers(currentSubsection),
			]
		}
		// Filters out masked responses from the allQuestions (depends on hasEdit flag)
		const maskedQArray = getMaskedResponses(questionList, answersArray)
		if (maskedQArray.length) {
			const maskedQIds = [];
			maskedQArray.forEach(arr => {
				const answeredObject = answersArray.filter(
					(item) => item.question_id === arr.question_id && !item.hasEdit,
				);
				if (answeredObject.length) maskedQIds.push(arr.question_id);
			})
			allQuestions = allQuestions.filter(q => !maskedQIds.includes(q.question_id));
		}

		// allQuestions = allQuestions.filter(q => !q?.question_id?.match(/residencestatedisplay/i))
		allQuestions = allQuestions.map(q => omit(q, 'hasEdit'));
		allQuestions = allQuestions.map(q => omit(q, 'hasMask'));

		let listQId = '';
		let listIdx = null;
		const listQ = allQuestions.filter(q => q.listParentId === otherParams?.listParentId)
		const isListQReflexTrigger = !!listQ.filter(q => q.question_id === otherParams.questionId).length
		const isListAddAction = !!listQ.length;
		const isListEditAction = !!listQ.filter(
			q => Number.isInteger(q.listIndex) && q.listIndex >= 0).length;
		if (listQ.length) {
			const { listParentId, listIndex } = listQ[0];
			listQId = listParentId;
			listIdx = listIndex;
		}

		// Don't add list_completed_flag in case of list edit action
		if (!isListEditAction) {
			const answeredListQuestion = getAnsweredListQuestion(currentSection);
			allQuestions = appendListCompletionFlag(allQuestions, answeredListQuestion)
		}
		allQuestions = formatListQuestions(allQuestions, currentQuestionList);

		let payload = prepareQuestionsPayload(
			allQuestions,
			state.meta.listRelation[activeSubsectionId],
			otherParams.removeIndex,
		);

	    // Checking if there is any html string and removing its object in payload to fix cloudfare issue
	    /* eslint-disable no-param-reassign */

		payload = payload.filter((quesn) =>
			/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(quesn.answer) === false,
		);


		let reqParams = {
			uid,
			page_sequence_number: pageSeqNumber,
			questions: payload,
			...(otherParams?.listIndex >=0 && { current_list_index: otherParams?.listIndex, current_list_answer_index: otherParams?.listIndex }),
		};

		// Appends audit info with request
		// https://sureify.atlassian.net/browse/AS-1183
		const isAuditRequired = get(currentSubsection, 'otherParams.audit_required', false);
		if (isAuditRequired) {
			reqParams = {
				...reqParams,
				audit_info: prepareAuditPayload(meta, currentSubsection),
			}
		}

		if (!hasModal && completed) {
			reqParams = {
				...reqParams,
				index_modify_flag: 1,
			};
		} else {
			reqParams = {
				...reqParams,
				next_page_flag: 1,
			};
		}

		if (otherParams.isReflexive) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				reflexive_question_flag: 1,
			}
		}

		if (otherParams.validate) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				dob_validation_flag: 1,
			}
		}

		if (action) {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			if (action === 'CANCEL_UPDATE')
				reqParams = omit(reqParams, 'questions');
			reqParams = {
				...reqParams,
				action,
			}
		}

		if (activeSubsectionId === 'quotereview') {
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = {
				...reqParams,
				next_page_flag: 1,
			}
		}

		if (isListEditAction && isListQReflexTrigger) {
			reqParams = {
				...reqParams,
				index_modify_flag: 1,
				list_question_id: listQId,
			}
		}

		if (!isEmpty(otherParams.actionProps)) {
			const { actionProps } = otherParams;
			reqParams = omit(reqParams, 'index_modify_flag');
			reqParams = omit(reqParams, 'next_page_flag');
			reqParams = {
				...reqParams,
				...actionProps.body,
			}
		}
		if(signaturePages.indexOf(activeSubsectionId) > -1 && reqParams.next_page_flag) {
			reqParams = {
				...reqParams,
				signature_completed: true,
				ip_address: otherParams.ipAddress,
				utc_time: getUTCTimestamp(),
				user_time: getFormatDate(),
				user_time_zone: new Date().getTimezoneOffset(),
			}
		}

		dispatch(getNextQuestionSet(url, reqParams, {
		  ...otherParams,
		  maskedQArray,
		  sectionIndex,
		  sectionId: get(currentQuestionList[0],'question_id',''),
		  isListAddAction,
		  ...reqParams,
		}));
		if (otherParams.isReflexive && isListEditAction) {
			dispatch({
				type: SET_REFLEXIVE_LIST_INDEX,
				index: listIdx,
				id: listQId,
			});
		}
	};
};

export const setCurrentBreadcrumb = (id) => ({
  type: SET_CURRENT_BREADCRUMB,
  id,
})

export const toggleSignatureContinueButton = (btnState, sectionIndex) => ({
	type: TOGGLE_SIGNATURE_CONTINUE,
	btnState,
	sectionIndex,
})

export const toggleESignButton = (enable) => ({
	type: TOGGLE_ESIGN_BUTTON,
	enable,
})

export const setPDFConfigs = (pdfBlobURL, numPages) => ({
	type: SET_PDF_CONFIGS,
	url: pdfBlobURL,
	pages: numPages,
})

export const IllustrationPDFFetchAction = ({
	caseId,
	dataBuffer,
	sectionIndex,
	activeSubsectionId,
}) => {
	return (dispatch) => {
		dispatch({
			type: 'ILLUSTRATION_PDF_FETCH',
			payload: {
				sectionIndex,
				caseId,
				dataBuffer,
			},
			activeSubsectionId,
		});
	}
}


const answerIsAMatch = (allAnswers, currentAnswer) => {
  if (isArray(currentAnswer)) {
    return intersection(allAnswers, currentAnswer).length!==0;
  }
  let answer = currentAnswer

  if (isObject(currentAnswer)) {
    answer = currentAnswer.id;
  } else if (!Number.isNaN(Number.parseFloat(answer))) {
    answer = Number.parseFloat(currentAnswer);
  }

  answer = allAnswers?.filter(a => {
	try {
		  if (isObject(currentAnswer)) {
			return (a.toString() === (currentAnswer.id).toString())
		  }
		  if (isObject(a))
			  if((a.id).toString() === (currentAnswer).toString())
				  return ((a.id).toString() === (currentAnswer).toString())

		  return a.toString() === currentAnswer.toString()
	  } catch (e) {
		  console.error('Error in filter', e)
		  return false
	  }
  }).length
  return answer;
}

/**
 * @description function determines if reflexive question API call
 * should be done.
 * @param {Array} questionsArray existing questions and answers
 * @param {Array} childQuestionsOn options for which child questions
 * will be sent
 * @param {String} currentQuestionId
 * @param {String} currentAnswer
 * @return {Boolean}
 */
export const shouldMakeReflexiveCall = (
	questionsArray,
	childQuestionsOn,
	currentQuestionId,
	currentAnswer,
	ignoreChildQuestionsOn,
	isError=false,
) => {
	const hasAnswer = questionsArray.filter(
		(question) => question.question_id === currentQuestionId,
	)[0];

	const response = hasAnswer ? hasAnswer.response : undefined;
	if(hasAnswer && hasAnswer.validations?.error_message_percentage!== '' && isError){
		return false;
	}
	if((response || currentAnswer) && ignoreChildQuestionsOn){
		return true;
	}


	if (childQuestionsOn && answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return true;
	}
	// if there are no answer eligible for reflexive calls, return false.
	if (
		!childQuestionsOn ||
		(isArray(childQuestionsOn) && childQuestionsOn.length === 0)
	) {
		return false;
	}

	/**
	 * if there is no answer and the current answer is not part
	 * of child_questions_on array, do not make the api call.
	 * For every other condition, make the api call.
	 */
	if (!(response || response === 0) && !answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return false;
	}

	/**
	 * if we have an answer and this answer is not in the
	 * child_question_on array, AND
	 * if the new answer is also not in the child_questions_on
	 * array then do not make the api call.
	 */
	return !(response &&
		answerIsAMatch(childQuestionsOn, hasAnswer.response) &&
		answerIsAMatch(childQuestionsOn, currentAnswer));

};

const getListParentQuestion = (questionList, parentId) => {
	let listParentQ = questionList.filter(q => q.question_id === parentId)
	// let listParentQ = [];
	if(!listParentQ.length){
		const result = questionList.find(q => q.child_questions && q.child_questions_on).questions;
		const question = result.find(q => q.question_id === parentId)
		if(question){
			listParentQ = [question]
		}

	}
	if(listParentQ?.length > 1){
		return listParentQ
	}
		return listParentQ[0]

}

export const updateAnswersArray = (
	payload = {
		sectionIndex: 0,
		response: '',
		questionId: '',
		hasEdit: false,
		hasMask: false,
		questionResponse: {},
		preventReflexiveCall: false,
		isError: false,
		validate: false,
		ignoreChildQuestionsOn: false,
		propQuestion: null,
		listParentId: '',
		listIndex: null,
		rootListIndex: null,
		rootListId: '',
		currentStatus: null,
		displayType: null,
		auditRequired: false,
		makeApiCall: false,
		relativeQId: '',
		isDateCleared: false,
		questionType: null,
		isSelectiveFieldValidate: false,
		transactionLog: [],
	},
) => {
	return (dispatch, getState) => {
		if (
			payload.response ||
			payload.isError ||
			payload.response === '' ||
			payload.response === 0
		) {
			const currentState = getState();
			const {
				breadcrumbs,
				activeSubsectionId,
				currentPageSeqNumber,
				eSign,
			} = currentState.questions;
			const currentBreadcrumbId = get(
				breadcrumbs,
				'currentBreadcrumb.id',
				'',
			);
			const {
				sectionIndex,
				response,
				questionId,
				preventReflexiveCall,
				validate,
				ignoreChildQuestionsOn,
				propQuestion,
				listParentId,
				listIndex,
				rootListIndex,
				rootListId,
				auditRequired,
				hasEdit,
				makeApiCall,
				displayType,
				isError,
				relativeQId,
				isDateCleared,
				questionType,
				isSelectiveFieldValidate,
			} = payload;

			const currentSubSection = currentState.questions.sections[activeSubsectionId][sectionIndex];
			const { questionList, answersArray, mrasParentQMap = {} } = currentSubSection;

			let relativeQIdAnswer = '';
			let answeredQs = []
			if(answersArray && answersArray.length > 1){
				relativeQIdAnswer = answersArray.filter(ans => ans?.question_id === relativeQId)[0]?.answer
			}

			const conditionalQuestions = questionList.filter((ques) => ques?.validations?.relative)
			answeredQs = conditionalQuestions.reduce((acc, condQ) => {
							let eachQuestion = answersArray.filter((eachAns) => (eachAns.question_id === condQ.question_id) && eachAns.answer !== '')[0]
							if(eachQuestion){
								eachQuestion = { ...eachQuestion, relativeQId: condQ.validations.relative.questionId }
								acc.push(eachQuestion)
							}
							return acc;
						},[])
			if(answeredQs.length){
				let eachRelativeQId; const questionIds = [];
				const relativeQIds = answeredQs.reduce((acc, answeredQ, index) => {
					if(index === 0 || (index !==0 && answeredQ.question_id !== eachRelativeQId)){
						acc.push(answeredQ.relativeQId)
						questionIds.push(answeredQ.question_id)
					}
					eachRelativeQId = answeredQ.relativeQId
					return acc;
				},[])
				dispatch({
                    type: 'UPDATE_REQUIRED_SCHEMA',
					activeSubsectionId,
                    payload: {
						questionIds,
						relativeQIds,
						sectionIndex,
                    },
                })
			}

			if (questionId.indexOf('_$ureify_') > -1) {
				dispatch({
					type: UPDATE_QUESTION_ANSWER,
					currentBreadcrumbId,
					currentPageSeqNumber,
					payload: {
						...payload,
						questionId,
						propQuestion,
						auditRequired,
						eSign,
					},
					activeSubsectionId,
				});
				if (!preventReflexiveCall && propQuestion.child_questions) {
					dispatch(submitSectionQuestion(sectionIndex, {
						isReflexive: true,
						sectionIndex,
						preventReflexiveCall: true,
						questionId,
						listParentId: payload.listParentId,
					}))
				}
				return;
			}

			let question;
			const questionsArray = questionList
			let listQ = null
			let originalQuestions = null

			if (listParentId) {
				const listParentQ = questionList.filter(q => q.question_id === listParentId)[0];

				if(listParentQ){
					originalQuestions = get(listParentQ, 'original_questions', null)
					const currentListIndex = listIndex >= 0 ? listIndex : currentState?.questions?.listIndex
					if((Number.isInteger(currentListIndex) && currentListIndex > -1) && listParentQ.questions.length && listParentQ?.child_questions_completed_flag){
						listQ = listParentQ.questions[currentListIndex]
					}else {
						listQ = listParentQ?.child_questions_completed_flag ? listParentQ?.questions : originalQuestions
					}

					if(!listQ?.length){
						[question] = listQ
					}else {
                        const flattenedListQ = [];
                        HELPER.flattenQuestions(listQ, flattenedListQ);
                        [question] = flattenedListQ.filter(q => q.question_id === questionId)
                    }
				}else {
					/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
					listQ = questionList.filter(q => q.display_type === 'list')[0];
					if(Number.isInteger(rootListIndex)){
						const flattenedListQ = [];
						HELPER.flattenQuestions(listQ, flattenedListQ);
						question = listQ
					}else {
						originalQuestions = get(listQ, 'original_questions', null)
						question = getListParentQuestion(originalQuestions, listParentId)
					}

				}
			}
			else {
				[question] = questionList.filter(
					(q) => q.question_id === questionId,
				);
			}

			const parentId = get(question, 'properties.parent_group_id', '');
			const parentQuestion = questionList.filter(
				(q) => q.question_id === parentId,
			)[0];

			let isDataSelector = false;
			const currentQuestionSet = get(parentQuestion, 'questions', []);
            if(currentQuestionSet.length > 0) {
			   isDataSelector = currentQuestionSet[0].display_type === 'data_selector';
			}

			let reflexCall = false;
			if (!preventReflexiveCall && question && question.child_questions) {
				let childQsOnList = question.child_questions_on
				if(question.question_type=== 'singleselection' && question.display_type === 'dropdown') childQsOnList = propQuestion.child_questions_on
				reflexCall = shouldMakeReflexiveCall(
					questionsArray,
					childQsOnList,
					questionId,
					response,
					ignoreChildQuestionsOn,
					isError,
				);
			}

			if(reflexCall || (question?.display_type === 'date' && question?.reflexive_question_flag)) {
				const { transactionLog } = payload;
				const { meta } = currentState;
				const { os, userAgent, platform } = meta;
				const previousTransactionLog = transactionLog && transactionLog.length ? transactionLog : [];
				const timeStampData = {
					transactionLog:
					[
						...previousTransactionLog,
						{
							time_start: getUTCTimestamp(),
							time_end: getUTCTimestamp(),
							os,
							browser: userAgent,
							platform,
						},
					],
					lastSubmittedAt: getUTCTimestamp(),
				};
				dispatch({
					type: UPDATE_QUESTION_ANSWER_TIME_STAMP,
					payload : {
						questionId,
						timeStampData,
						activeSubsectionId,
						sectionIndex,
						meta,
					},
				})
			}

			dispatch({
				type: UPDATE_QUESTION_ANSWER,
				currentBreadcrumbId,
				currentPageSeqNumber,
				payload: {
					...payload,
					auditRequired,
					eSign,
				},
				activeSubsectionId,
			});

			if(isDataSelector){
				dispatch({
					type: UPDATE_DATA_SELECTOR_OPTIONS,
					currentBreadcrumbId,
					currentPageSeqNumber,
					 payload: {
						 ...payload,
						 questionId,
						 parentQuestion,
					 },
					 activeSubsectionId,
				})
			}

			if(payload.currentStatus === 'products' && payload.displayType === 'product_card') {
				dispatch(submitSectionQuestion(sectionIndex));
				return;
			}
			const invalidQuestions = questionList.filter(q=> q.validAnswer===false);  // bh-307 make api call if all the questions in that section are valid
			const listQCheck = questionList.filter(q => q.display_type === 'list');

			let parentQIds = []
			if(Object.keys(mrasParentQMap)?.length > 0){
				parentQIds = Object.keys(mrasParentQMap);
			}

			if(!invalidQuestions.length || parentQIds.includes(questionId)){
				// check if reflexive answer
			if (!preventReflexiveCall || makeApiCall) {
				if (reflexCall || makeApiCall) {
					if(displayType === 'radio_button' && listQCheck?.length > 0){
						dispatch({
							type: 'SET_ROOT_LIST_INDEX',
							index: null,
						});
						dispatch({
							type: 'SET_NESTED_LIST_INDEX',
							index: -1,
						});
					}
					dispatch(submitSectionQuestion(sectionIndex, {
						isReflexive: true,
						listIndex,
						listParentId: payload.listParentId,
						preventReflexiveCall: true,
						questionId,
						sectionIndex,
					}));
				}
			}
		}
			if (validate && response) {
				dispatch(
					submitSectionQuestion(sectionIndex, {
						validate: true,
						sectionIndex,
						preventReflexiveCall: true,
						questionId,
					}),
				);
			}
		}
	};
};

export const updateQuestionAnswerTimeStamp = (questionId, timeStampData, sectionIndex) => {
	return (dispatch, getState) => {
		const currentState = getState();
			const {
				activeSubsectionId,
			} = currentState.questions;
		dispatch({
		type: UPDATE_QUESTION_ANSWER_TIME_STAMP,
			payload : {
				questionId,
				timeStampData,
				activeSubsectionId,
				sectionIndex,
			},
		})
	}
}

export const makeQuestionsCall = () => {
	return (dispatch, getState) => {
		const currentState = getState();
		const { uid } = currentState;
		const {
			breadcrumbs,
			currentPageSeqNumber,
			breadcrumbIndex,
		} = currentState.questions;
		const { breadcrumbList } = breadcrumbs;
		const currentBreadcrumbIndex = breadcrumbIndex - 1;
		if (
			currentBreadcrumbIndex > -1 &&
			breadcrumbList[currentBreadcrumbIndex].clickable
		) {
		 const currentBreadcrumb = breadcrumbList[currentBreadcrumbIndex];
		 const params = {
			uid,
			breadcrumb_id: currentBreadcrumb.breadcrumb_id,
			breadcrumb_nav_flag: true,
			page_sequence_number: currentPageSeqNumber,
		};
		dispatch(setCurrentBreadcrumb(currentBreadcrumb.breadcrumb_id));
        dispatch(getNextQuestionSet('/questions', params));
		}
	};
};

export const getNextPage = (params) => {
	return (dispatch, getState) => {
		const { uid, questions } = getState();
		const { currentPageSeqNumber } = questions;

		const reqParams = {
			uid,
			page_sequence_number: currentPageSeqNumber,
			...params,
		}

		dispatch(getNextQuestionSet('/questions', reqParams, {
			...reqParams,
		}));
	}
}

export const goToPreviousBreadCrumb =(sectionIndex)=>{
    return (dispatch, getState) => {
      const currentState = getState()
      const { currentPageSeqNumber } = currentState.questions;
      const { uid } = currentState;
      const reqParams = {
        uid,
        page_sequence_number:currentPageSeqNumber,
        prev_page_flag: true,
      }
      dispatch(getNextQuestionSet('/questions', reqParams, {
        ...reqParams,
        sectionIndex,
      }, reqParams));
    }
  }
