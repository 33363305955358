/* eslint-disable react/style-prop-object */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'crux';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import at from 'lodash/at';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import TextElement from '../TextElement';
import {
  getNextListItemForm,
  deleteBeneficiary,
  addListItem,
  setListIndex,
  setReflexiveListIndex,
  listSectionUpdates,
  setNestedListIndex,
  setRootListIndex,
} from '../../../redux/actions/listQuestion';
// eslint-disable-next-line import/no-cycle
import ListItemCard from './ListItemCard';
import { insertReflexiveQuestions } from '../../../utils';
import { getCSSVariables } from '../../../cruxComponents/utils';
import { getEnableAdd } from '../../../redux/selector/validations';
import { SET_LIST_MODE } from '../../../redux/types';
import { getListIndexRequiredSchema } from '../../../redux/actions/listQuestionHelper';
import { ListContext } from '../../List/ListContext';
import {fetchDropdownResponseOptionsMap} from '../../List/ListQuestions';

// const ListItemCard = lazy(() => import('./ListItemCard'));

// Handles reflexLevel condition for displaying Contingent Beneficiary
const Wrapper = styled.div`
  ${({ reflexLevel, isReview }) => (reflexLevel < 1 || (reflexLevel === 1 && isReview)) && `
    background: rgba(226, 226, 226, 1);
    margin-right: -100px;
`}
  margin-left:${({ isReview }) => isReview ? '0px' : '-100px'};
  margin-right:${({ isReview }) => isReview ? '0px' : '-100px'};
  margin-bottom: 20px;

  ${({ margin, padding })=>
  `
    margin:${margin?.[0]?.['listQuestionCard-wrapper'] || margin?.[1]?.['listQuestionCard-wrapper']};
    padding:${padding?.[0]?.['listQuestionCard-wrapper'] || padding?.[1]?.['listQuestionCard-wrapper']};
  `}

  ${({ listId, reflexiveCardListId, listDisplay }) => listDisplay && (listId === reflexiveCardListId) && `
    > div + button {
      position: absolute;
      bottom: 22px;
      left: 3%;
    }
  `}
`;

const AddListItemButton = styled.button`
  border: 2px dashed rgba(41, 101, 204, 1);
  border-radius: 8px;
  background: transparent;
  padding: 20px;
  width: 100%;
  color: rgba(41, 101, 204, 1);
  text-align: left;
  font-size: 18px;
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
    border: ${({ listButtonsTheme }) => listButtonsTheme ?.borderOnFocus};
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    padding: 10px;
  }
`;

const Icon = styled.i`
  padding-right: 10px;
`;

const toArray = (obj) => {
  return Object.keys(obj).reduce((acc, keys) => {
    if (isObject(obj[keys])) {
      acc.push(obj[keys])
    }
    return acc;
  }, [])
};

export const getQuestions = (questionList) => {
  const title = questionList[0];
  const{ original_questions:originalQuestions, child_questions_completed_flag: childQsCompletedFlag }=questionList[0];
  const { questions } = questionList[0];

  // if already added list questions are not available, don't display
  if (!questions) {
    return {
      title,
      questions,
      childQsCompletedFlag,
    }
  }

  return {
    title,
    questions,
    originalQuestions,
    childQsCompletedFlag,
  }
}

function ListQuestionCard({
  questionList,
  handleAddListItem,
  sectionIndex,
  handleDelete,
  handleUpdate,
  updateListIndex,
  addButtonText,
  changeMode,
  reflexLevel,
  isReview,
  listParentId,
  listEditIndex,
  listEditQId,
  handleCancel,
  updateReflexiveListIndex,
  maxListItems,
  isEditMode = false,
  isNestedList,
  listMode,
  listDisplay,
  listQMap,
  rootListIndex,
  rListDisplay,
  rootListId,
  editModeParam,
  editModeIndex,
  listIndex,
  updateListMode,
  isEditable,
  listError,
  state,
  activeSubsectionId,
  requiredListQIds,
  stateSectionIndex,
  listId,
  listParentQId,
  curListParentQs,
  curBreadcrumbSections,
  ...otherProps
}) {

  const { listState, handleListAction } = useContext(ListContext)

  const theme = get(otherProps, 'theme.config.theme.global');
  const { listButtonsTheme, colorScheme } = theme;
  const [editIndex, updateEditIndex] = useState(-1);
  const { title, questions, originalQuestions, childQsCompletedFlag } = getQuestions(questionList);
  let questionsAry = isEditMode ? [originalQuestions] : questions;
  const listProperties = get(questionList[0], 'properties', null);
  questionsAry = questionsAry.map(item => toArray(item));
  questionsAry = questionsAry.map(item => insertReflexiveQuestions(item));

  const currentSecData = curBreadcrumbSections?.[sectionIndex]
  if(!!currentSecData && currentSecData?.dropdownResponseOptions){
		const {dropdownResponseOptionsMap, dropdownResponseOptionsQIds} = fetchDropdownResponseOptionsMap(currentSecData?.dropdownResponseOptions)
		for(let i = 0 ; i < questionsAry.length ; i += 1){
			const eachListIndex = questionsAry[i];
      for(let j = 0 ; j < eachListIndex.length ; j += 1){
        const eachListQs = eachListIndex[j];
        if(dropdownResponseOptionsQIds?.length && dropdownResponseOptionsQIds?.includes(eachListQs.question_id)){
          eachListIndex[j] = {
            ...eachListQs,
            response_options: dropdownResponseOptionsMap[eachListQs?.question_id]?.response_options,
            child_questions_on: dropdownResponseOptionsMap[eachListQs?.question_id]?.child_questions_on,
          }
        }
      }
		}
	}

  let hiddenListQsObj;
  for (const [index, eachList] of questionsAry.entries()) {
    const hiddenListQIds = [];
    for (const listQ of eachList) {
      if (listQ.is_hidden)
        hiddenListQIds.push(listQ.question_id);
    }
    hiddenListQsObj = {
      ...hiddenListQsObj,
      ...(hiddenListQIds.length && {
        [index]: {
          hiddenListQIds,
          listError,
          enableAdd: getEnableAdd(state, stateSectionIndex, activeSubsectionId, requiredListQIds, true, hiddenListQIds),
        },
      }),
    };
  }
  // CSS Variables
  const cssVariables = getCSSVariables(otherProps.theme);
  const margin = at(cssVariables, ['pageOverride.margin', 'global.margin']);
  const padding = at(cssVariables, ['pageOverride.padding', 'global.padding']);
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);
  if (listButtonsTheme && listButtonsTheme.addButtonTheme) {
    listButtonsTheme.addButtonTheme.themeConfiguration.font = font?.[0]?.['button-list-add'] || font?.[1]?.['button-list-add']
  }

  const _margin = margin?.[0]?.['listQuestionCard-wrapper'] || margin?.[1]?.['listQuestionCard-wrapper'];
  if(margin?.[0]?.['listQuestionCard-wrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[0]['listQuestionCard-wrapper'] = shortHandMargin.join(' ');
  }
  if(margin?.[1]?.['listQuestionCard-wrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[1]['listQuestionCard-wrapper'] = shortHandMargin.join(' ');
  }

  let listWrapperElement; let listWrapperPEl
  let reflexiveCardContainer = document.querySelector(`div[id*="reflexive-card-container-${listParentId}"] > div:first-child`)
  let reflexiveCardListId = reflexiveCardContainer?.getAttribute('data-list-id');
  if(!reflexiveCardContainer){
    Array.from(document.querySelectorAll('div[id*="reflexive-card-container"]')).forEach((element) => {
      reflexiveCardContainer = element.querySelector(`div[data-list-id=${listParentId}]`)
      if(reflexiveCardContainer)
        reflexiveCardListId = reflexiveCardContainer?.getAttribute('data-list-id');
    })
  }

  const handleResize = () => {
    listWrapperElement = document.querySelector(`div[id*='list-item-card-wrapper-${listId}']`);
    listWrapperPEl = listWrapperElement?.parentElement?.parentElement
    if(listWrapperPEl && reflexLevel <= 0){
      const paddingL = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-left');
      const paddingR = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-right');
      listWrapperElement.style.marginLeft = `-${paddingL}`;
      listWrapperElement.style.marginRight = `-${paddingR}`;
    }
  }

  const wrapperProps = {
    listId,
    reflexiveCardListId,
    listDisplay,
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize, false);
  }, [listWrapperPEl?.clientWidth])

  useEffect(() => {
    if(editModeParam && editModeIndex >= 0){
      updateListMode(true);
      updateListIndex(editModeIndex);
      updateEditIndex(editModeIndex);
      if(isNestedList){
        changeMode('nestedListEdit')
      }else if(!isNestedList){
        changeMode('listEdit')
      }
    }
  },[editModeParam, editModeIndex])

  const renderAddListItemButton=(childQLength)=>{
    let disableListItemAddBtn = false;
    if(listState?.listId) disableListItemAddBtn = (listId !== listState?.listId)
    const listActionId = 'add-list-item-btn';
    if(listButtonsTheme && listButtonsTheme.addButtonTheme){
      return (
        <Button
          label={ addButtonText }
          handleButtonClick={ () => {
            handleListAction({
              listId,
              listParentQId,
              curListParentQs,
              action: 'addListItemBTN',
            });
            handleAddListItem(rootListIndex, childQLength, listMode, rootListId, listActionId)
          } }
          color={ colorScheme }
          disabled={ disableListItemAddBtn }
          { ...listButtonsTheme.addButtonTheme }
        />
      )
    }
    return (
      <AddListItemButton
      name='add-list-item-button'
      onClick={ () => handleAddListItem(rootListIndex, childQLength, listMode, rootListId, listActionId) }
      listButtonsTheme={ listButtonsTheme }
    >
      <Icon className='fa fa-plus-circle' />
      <span>{ addButtonText }</span>
    </AddListItemButton>
    )
  }

  return (
    <div>
      <TextElement
        key={ title.question_id }
        styleType={ isReview ? 'heading' : 'questions_group' }     // this needs to be fixed.
        text={ title.question_text }
        sectionIndex={ sectionIndex } />
        {
          title.question_status_message &&
          <TextElement
            key={ `${title.question_id}-error` }
            styleType='error'
            text={ title.question_status_message }
            sectionIndex={ sectionIndex } />
        }
      <Wrapper margin={ margin } padding={ padding } id={ `list-item-card-wrapper-${listId}` } name='list-item-card-wrapper' reflexLevel={ reflexLevel } isReview={ isReview } { ...wrapperProps } >
        {

          questionsAry ?
          questionsAry.map((item, index) => {
            const listActionId = 'list-delete-btn';
            const requiredListIds = getListIndexRequiredSchema(item, curBreadcrumbSections);
            let enableUpdate = !listError && getEnableAdd(
              state,
              sectionIndex,
              activeSubsectionId,
              requiredListIds,
            )

            const getAgentDataButton = document.querySelector('button[id*=\'get-agent-data-button\']');
            if(getAgentDataButton)
              enableUpdate = enableUpdate && listState?.subListAction?.match(/sub_list_submit/i)

            return (
              <ListItemCard
                index={ index }
                len={ questionsAry.length }
                reflexLevel={ reflexLevel }
                title={ `${index + 1 }. ${title.question_text}` }
                name='list-item-card'
                changeMode={ changeMode }
                onDeleteClick={ () => {
                  handleDelete(index, sectionIndex, listActionId, rootListIndex, rootListId);
                } }
                onEditClick={ () => {
                  handleUpdate(sectionIndex, index, item, rootListIndex, questionsAry.len, rootListId);
                } }
                setEditIndex={ () => {
                  updateListIndex(index, rootListIndex);
                } }
                sectionIndex={ sectionIndex }
                /** Commenting Hidden List Qs Validations for Update Button */
                // hiddenListQsObj[index] ? (!hiddenListQsObj[index].listError && hiddenListQsObj[index].enableAdd) :
                enableUpdate={ enableUpdate }
                isEditMode = { isEditMode || (index === editIndex) ||
                  (listEditIndex === index && listEditQId === listParentId) ||
                  (editModeParam && (index === editModeIndex))
                }
                updateEditIndex = { updateEditIndex }
                listItem={ item }
                isReview= { isReview }
                listParentId={ listParentId }
                isNestedList = { isNestedList }
                listMode = { listMode }
                listDisplay = { listDisplay }
                listProperties= { listProperties }
                listId = { listId }
                listParentQId = { listParentQId }
                curListParentQs = { curListParentQs }
                rootListId = { rootListId }
                rootListIndex = { rootListIndex }
                rListDisplay = { rListDisplay }
                editIndex = { editIndex }
                isEditable = { isEditable }
                childQsCompletedFlag = { childQsCompletedFlag }
                setReflexiveListIndex={ (idx, id) => {
                  updateReflexiveListIndex(idx, id);
                } }
                handleCancel={ (isCancelUpdate) => handleCancel(sectionIndex, item, index, isCancelUpdate) }
                isCancelBtnDisabled= { otherProps.isCancelBtnDisabled }
                />
            )
          })
          :
          null
        }
        {
          editIndex === -1 && !isReview && (+maxListItems !== questions.length) &&
          ((isNestedList && !rListDisplay && !listMode) || (isNestedList && listMode && (rootListIndex === listIndex)) ||
           (!isNestedList && !editModeParam)) && (!isNestedList && listId !== listEditQId) &&
          isEditable && (renderAddListItemButton(questionsAry.length))
        }
      </Wrapper>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { sectionIndex, listError, nestedListIndex } = props;
  const { listEditIndex, listEditQId, activeSubsectionId, listMode, listQMap, listIndex } = get(state, 'questions');
  /** this snippet gives generic validation on list update button */
  // const enableUpdate = !listError && getEnableAdd(
	// 	state,
	// 	sectionIndex,
  //   activeSubsectionId,
  //   requiredListQIds,
  //   undefined,
  //   undefined
  // );
  return {
    listEditIndex,
    listEditQId,
    listMode,
    listQMap,
    listIndex,
    nestedListIndex,
    listError,
    state,
    stateSectionIndex: sectionIndex,
    activeSubsectionId,
  }
}

function mapDispatchToProps(dispatch, props) {
  const { isNestedList, nestedListIndex, listId } = props
  return {
    handleAddListItem: (rootListIndex, childQLength, listMode, rootListId, listActionId) => {
      if(isNestedList){
        dispatch(setRootListIndex(rootListIndex))
        dispatch(setNestedListIndex(childQLength))
        if(listMode){
          dispatch({
            type: SET_LIST_MODE,
            index: listMode,
          })
        }
      }else if(!isNestedList && nestedListIndex>=0) {
        dispatch(setRootListIndex(childQLength))
        dispatch(setNestedListIndex(0))
      }
      dispatch(getNextListItemForm(props.sectionIndex, props.listId, rootListIndex, childQLength, isNestedList, rootListId, listActionId));
    },
    handleDelete: (index, sectionIndex, listActionId = undefined, rootListIndex, rootListId) => {
      dispatch(deleteBeneficiary(index, sectionIndex, props.listId, listActionId, rootListIndex, isNestedList, rootListId))
    },
    handleUpdate: (sectionIndex, index, listQ, rootListIndex, childQLength, rootListId) => {
      /** Setting List Index on update click */
      // if(!isNestedList){
      //   dispatch(setListIndex(index));
      // }
      const listQIds = listQ.map(q => q.question_id);
      dispatch(addListItem(sectionIndex, index, listQIds, listId, rootListIndex, isNestedList, undefined, childQLength, rootListId));
    },
    updateListIndex: (index, rootListIndex) => {
      dispatch(setListIndex(index, rootListIndex));
    },
    updateReflexiveListIndex: (index, id) => {
      dispatch(setReflexiveListIndex(index, id));
    },
    handleCancel: (sectionIndex, listQ, index, isCancelUpdate) => {
      const listQIds = listQ.map(q => q.question_id);
      dispatch(listSectionUpdates(sectionIndex, listQIds, index, isCancelUpdate, listId, `list-cancel-btn-${listId}`));
    },
    updateListMode: (listMode) => {
      dispatch({
        type: SET_LIST_MODE,
        index: listMode,
      })
    },
  }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ListQuestionCard));

