import React, { useCallback, useEffect, useState, useRef } from 'react';
import get from 'lodash/get';
import { at } from 'lodash';
import { connect } from 'react-redux';
import { Button } from 'crux';
import styled, { withTheme } from 'styled-components';
import LocalStorageServices from '../../util/localStorageServices';
import { HeaderWrapper, Links, Logo, ButtonWrapper } from './styles';
import CustomHeader from './CustomHeader';
import { removeAuthHeaders } from '../../redux/actions/authHandler';
import { isIe } from '../../utils';
import { getCSSVariables } from '../../cruxComponents/utils';
import {getCurrentQuestionSet} from '../../redux/selector/questions';

const HeaderText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 2%;
	> :first-child {
		font-weight: bold;
	}
`

const Header = (props) => {
	const {
		layout,
		background,
		screenViewport,
		loading,
		handleLogout,
		pathname,
		hasPopUpModal,
		otherParams,
		customHeader: { isPresent, ...customHeader } = {},
	} = props;
	const [fade, setFade] = useState(false);
	const scrollListener = useCallback(() => {
		if (screenViewport === 'mobile') {
			return;
		}
		const verticalOffset = window.location.pathname.includes('questions') ? 50 : 0;
		if (window.scrollY > verticalOffset) {
			setFade(true);
		} else {
			setFade(false);
		}
	}, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

	// Tab Navigation
	const focusRef = useRef();
	useEffect(() => {
		if (focusRef && focusRef.current) {
			if (typeof focusRef.current.focus === 'function') {
				focusRef.current.focus();
				setTimeout(() => {
					document.activeElement.blur();
				}, 10);
			}
		}
	}, [pathname]);

	const branding = get(props, 'theme.config.branding', {});
	const loginConfig = get(props, 'theme.config.features.login', false);
	const isLoginEnabled = get(loginConfig, 'enable', false);
	const isSAMLOn = get(loginConfig, 'isSAMLOn', false);

	const logoutButtonTheme = get(props, 'theme.config.theme.global.logoutButton.themeConfiguration', {});
	const hasLogoutButton = (isLoginEnabled && !isSAMLOn) && LocalStorageServices.getAccessToken() && !hasPopUpModal;
	// eslint-disable-next-line react/destructuring-assignment
	const cssVariables =  getCSSVariables(props.theme);
	const element = '.button-logout'
	const font = at(cssVariables, [`pageOverride.font${element}`, `global.font${element}`]);
	logoutButtonTheme.font=font[0] || font[1]
	return (
		<>
			{isPresent ? (
				<CustomHeader
				bannerStyles={ { ...customHeader.banner } }
				background={ background }
				branding={ branding }
				layout={ layout }
				/>
			) : (
			<header>
				<HeaderWrapper
					name='header-wrapper'
					screenViewport={ screenViewport }
					background={ background }
					layout={ layout }
					fade={ fade }
					isIe={ isIe() }
					loading={ loading?.toString() }
				>
					<Logo screenViewport={ screenViewport }>
						<a href={ branding.link } ref={ focusRef } target="_blank" rel="noopener noreferrer">
							<img
								src={
									layout === 'double'
										? branding.darkLogo
										: branding.lightLogo
								}
								alt={ branding.altText }
							/>
						</a>
					</Logo>
					<Links />

					{ hasLogoutButton && (
							<ButtonWrapper>
								<Button
									shape="rectangle"
									label="Logout"
									id="btn-logout"
									type="outline"
									color="blue"
									alignment="center"
									handleButtonClick={ handleLogout }
									themeConfiguration={ logoutButtonTheme }
								/>
							</ButtonWrapper>
						)
					}
					{
						otherParams?.applicant_id && (
							<HeaderText>
								<span>App Code:</span>
								<span>{otherParams?.applicant_id || ''}</span>
							</HeaderText>
						)
					}
				</HeaderWrapper>
			</header>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const { router } = state;
	const { otherParams } = getCurrentQuestionSet(state);
	const pathname = get(router, 'location.pathname', '');
	const hasPopUpModal = get(state, 'questions.popupDetails.show', false);
	return {
		pathname,
		hasPopUpModal,
		otherParams
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogout: () => {
			dispatch(removeAuthHeaders());
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
