/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

const QuestionText = styled.h3`
	font-size: 18px;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font: ${({ font }) => font};
`;

const QuestionDescription = styled.h4`
  color: ${({ color }) => color};
  font-size: 16px;
  font-family: ${({ fontFamily }) => fontFamily};
  font: ${({ font }) => font};
`;


const dateOverlapStyles = css`
/* Date Picker Component Overlap Style Adjacent Selector Changes - Styled Component Fix */
  div[id*="date-picker"] + div[name=label-wrapper] > label > span{
    white-space:unset;
  }
  div[id*="date-picker"] + div[name=label-wrapper]{
    top: -12px;
  }
  ${({ reflexiveIndex, listParentId }) => `
      div[id*="date-picker"] + div[name=label-wrapper] > label > span{
        width: ${(reflexiveIndex === 0 || listParentId) ? '99%' : (reflexiveIndex === 1 ? '96%' : '91.5%')};
      }
  `}
`

const inputOverlapStyles = css`
  /* Input Component Overlap Style Adjacent Selector Changes - Styled Component Fix */
  div[name=label-wrapper] > label > span{
    white-space:unset;
  }
  div[name=label-wrapper]{
    top: -12px;
  }
`;

const inputOverlapMediaStyles = css`
  @media only screen and (min-width: 320px) and (max-width: 479px) {
    div[name=input-components] + div[name=label-wrapper]{
      left: 7px;
      margin-right: 0;
    }
    div[name=input-components] + div[name=label-wrapper] > label {
      padding: 0;
    }
    div[name=input-components]{
      margin-top: -18px;
    }
    ${({ reflexiveIndex }) => reflexiveIndex === 1 &&
      `
        div[name=input-components] > input{
          width: 285px;
        }
      `
    }
  }
  ${({ questionText }) => questionText?.length > 38 &&
    `
      @media only screen and (min-width: 820px) and (max-width: 820px) {
        ${inputOverlapStyles}
      }
    `
  }
`

const selectOverlapStyles = css`
  div[name=select-wrapper] > div > div > div:nth-of-type(1) > div:nth-of-type(1){
    white-space:unset;
    width: inherit;
    ${({ value }) => value && 'top: -5px'}
  }

  div[name=select-wrapper]:focus-within > div > div > div:nth-of-type(1) > div:nth-of-type(1){
    top: -5px;
  }

  div[name=select-container] > div:nth-of-type(2) > div > div > div:nth-of-type(1) > div:nth-of-type(1){
    ${({ value }) => value && 'top: 50%'}
  }
`

const selectOverlapMediaStyles = css`
  @media only screen and (min-width: 320px) and (max-width: 479px) {
    div[id*="react-select-label"] > label > span {
      font: 400 14px Roboto;
    }
  }
  @media only screen and (min-width: 530px) and (max-width: 820px) {
    ${selectOverlapStyles}
  }
`

const InputWrapper = styled.div`
  display: flex;
  div[name=input-components] > textarea {
    resize: none;
  }
  ${({ questionType }) => questionType === 'text_area' && `
    div[name=input-components] {
      width: 100%;
    }
  `}
  ${({ metric }) => metric && `
    div[name=input-components]{
      overflow: clip;
    }
  `}
  ${({ label }) => label && label.label_text &&
    `
      display: grid;
      grid-template-columns: 100px 100%;
      align-items: center;
    `
  }
  div[name=tool-tip-wrapper] > div {
    z-index: 3009
  }

  ${({ marginSpacing }) => marginSpacing && `
    margin-bottom: 17px;
  `}

  div[name=input-components] > input:-webkit-autofill,
  div[name=input-components] > input:-webkit-autofill:hover,
  div[name=input-components] > input:-webkit-autofill:focus,
  div[name=input-components] > input:-webkit-autofill:active
  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  ${({ questionText, metric, actualThreshold, reflexiveIndex }) => !metric ?
    `div[name=input-components] + div[name=label-wrapper] {
      z-index: 3999;
    }` :
    reflexiveIndex === 0 && questionText?.length > 51 && questionText?.length < actualThreshold &&
    `div[name=input-components] + div[name=label-wrapper] > label > span{
      white-space:unset;
    }`
  }
  ${({ questionText, actualThreshold, metric, reflexiveIndex, listParentId }) =>
    !metric &&
      (questionText?.length <= actualThreshold ?
        (
          ( (reflexiveIndex === 0 || listParentId) && questionText?.length > 65 &&
            inputOverlapStyles ) ||
          ( reflexiveIndex === 1 && questionText?.length > 61 && inputOverlapStyles ) ||
          ( reflexiveIndex >= 2 && questionText?.length > 50 && inputOverlapStyles )
        ) :
        reflexiveIndex === 1 &&
          `
            div[name=input-components] + div[name=label-wrapper] {
              width: max-content;
            }
            div[name=input-components]:focus-within > input,
            div[name=input-components] > input {
              padding: 12px 0 15px 0;
              height: 20px;
            }
          `
      )
  }
    ${({ questionText, metric }) => !metric &&
      questionText?.length > 105 &&
      `
        div[name=label-wrapper] > label{
          padding-bottom: 0;
          margin-bottom: 0;
        }

      `
    }

  ${({ questionText, metric }) =>
    !metric &&
      (questionText?.length >= 30 && ( inputOverlapMediaStyles ))
  }

  div[name=input-components] > span
  {
    color: #4F4F4F
  }
  div[name=label-wrapper] {
    width: inherit;
  }

  ${({ questionText, metric, tooltipBody }) => metric && tooltipBody && questionText?.length > 59 && inputOverlapStyles }

  ${({ inlineWithLabel, metric, errorObj }) => inlineWithLabel &&
    `
      div[name=content-wrapper]{
        height: 52px;
      }

      ${metric ?
        `
          div[name=input-components]{
            overflow: visible;
          }
        ` :
        `
          ${errorObj?.error_message?.length > 24 &&
            `
              div[name=wrapper] > div + span {
                margin-left: -70%;
              }
            `
          }
        `
      }
    `
  }

  ${({ border, questionType }) => border==='none' && questionType==='text_area' &&`
      div[name=label-wrapper] > label{
        margin-bottom:0px;
        padding-bottom:0px;
        padding-left:5px;
      }

      div[name=label-wrapper] > label > span {
        font: 400 13px Roboto;
      }

      div[name=input-components] > textarea {
        font-size: 15px;
      }
  `}
  ${({ inputValue, relativeQId, relativeQIdAnswer }) => !inputValue && relativeQId && relativeQIdAnswer &&
    `
      div[name=input-components] > input{
        border-bottom: 1px solid #ccc;
      }

      span[id*="-error"]{
        visibility: hidden;
      }
    `
  }

  ${({ isEditable}) =>
    !isEditable &&
    `
      div[name=label-wrapper] > label {
        padding-left: 0;
      }

      div[name=input-components] > input{
        padding-left: 4px;
        padding-bottom: 0;
      }
    `
  }

  @media (min-width: 360px) {
    margin-right: 15px;
  }
  @media (max-width: 767px) {
    ${({ questionId }) =>
    questionId==='QR_EmailAddress' &&
    `
    flex-direction:column;
  `};
  }
`;

const SelectWrapper = styled.div`
${(props)=>props.floatingLabel?'':
   'margin-top: 11px;'}
  margin-right: 15px;
  @media (min-width: 768px) {
    margin: 11px 15px 4px 0px;
  }
  div[name=select-wrapper] > div > div > div + div > div {
    padding: 4px;
  }

  div[name=select-wrapper] > div > div > div > div + div > div > input {
    opacity: 0 !important;
  }

  div[name=select-wrapper]:focus-within > div > div > div > div + div > div > input {
    opacity: 1 !important;
  }

  ${({ isEditable }) =>
    !isEditable && `
      div[name=select-wrapper] > div > div{
        cursor: not-allowed;
      }
    `
  }

/** Label Style Changes */
  ${({ isCompleteBorder }) =>
    isCompleteBorder && `
      div[name=select-wrapper] > div > div > div {
        padding-left: 8px;
      }
      margin: 0px !important;
    `
  }

  ${({ questionTextLength, reflexiveIndex, listParentId, questionId }) =>
      questionTextLength < 80 ?
      (
        ( (reflexiveIndex === 0 || listParentId) && (questionTextLength > 56) && selectOverlapStyles ) ||
        ( reflexiveIndex === 1 && (questionTextLength > 54) && selectOverlapStyles ) ||
        ( reflexiveIndex >= 2 && (questionTextLength > 43) && selectOverlapStyles )
      ) :
      ((questionTextLength < 180) && !questionId.match(/BH_SmartCare/i)) &&
        `
          div[id*="react-select-label"] > label > span {
            font: 400 15px/26px Roboto;
          }
        `
  }

  ${({ questionTextLength }) =>
      questionTextLength >= 30 && questionTextLength < 80 && selectOverlapMediaStyles
  }

  ${({ border }) => border==='none' &&`
  div[name=select-wrapper] > div > div > div > div + div {
    color: rgb(51,51,51);
  }
  div[name=select-wrapper] > div > div{
        cursor: text;
      }
  `}
`

const Wrapper = styled.div `
  margin: ${({ customWrapperMargin })=>customWrapperMargin||'0 0 12% 0'};
  .rangeslider {
    margin: ${props => props.margin ? props.margin : '100px 0'}
  }

  .rangeslider__handle {
    background: ${props => props.background ? `${props.background  }!important` : 'rgba(226, 226, 226, 1)' };
  }

  .rangeslider__handle-tooltip {
    color: ${props => props.background ? `${props.background  }!important` : 'rgba(41, 101, 204, 1);'};
    text-align: center;
    font-size: 30px;
  }
`

const DateSelectWrapper = styled.div`
  /* Date Picker Component Width - Styled Component Fix */
  input[name*="date-picker"]{
    width: 100%;
  }
  ${({ questionText, reflexiveIndex, listParentId }) =>
    questionText?.length < 80 &&
    (
      ( (reflexiveIndex === 0 || listParentId) && (questionText?.length > 58) && dateOverlapStyles ) ||
      ( reflexiveIndex === 1 && (questionText?.length > 53) && dateOverlapStyles ) ||
      ( reflexiveIndex >= 2 && (questionText?.length > 41) && dateOverlapStyles )
    )
  }

  ${({ questionText }) => questionText?.length >= 54 && questionText?.length < 110 &&

    ` /* Width below specific to Level2Wrapper */
      div[id*="date-picker"] + div[name=label-wrapper] {
        z-index: 2000;
      }
    `
  }
  @media(max-width: 480px) {
    ${({ questionText }) => questionText?.length > 45 &&

    ` /* Width below specific to Level2Wrapper */
      ${dateOverlapStyles}
      div[id*="date-picker"] + div[name=label-wrapper] > label > span{
        width: 93%;
        :focus {
          top: -5px;
        }
      }
      div[id*="date-picker"] + div[name=label-wrapper] {
        z-index: 2000;
      }
    `
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    div[id*="date-picker"] + div[name=label-wrapper] {
      z-index: 2000;
    }
  }

  @media only screen and (min-width: 780px) {
    div[id*="date-picker"] + div[name=label-wrapper] {
      z-index: 2000;
    }
  }

  @media only screen and (min-width: 820px) and (max-width: 820px) {
    ${({ questionText }) => questionText?.length > 35 && `${dateOverlapStyles}`}
  }
`

const NumberInputWrapper = styled.div`
  ${({ marginSpacing }) => marginSpacing && ' margin-bottom: 17px; '}
  ${({ questionText, actualThreshold }) => questionText?.length > 51 && questionText?.length <= actualThreshold && inputOverlapStyles }
`

export { QuestionDescription, QuestionText, InputWrapper, NumberInputWrapper, SelectWrapper, Wrapper, DateSelectWrapper };
