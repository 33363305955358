import React from 'react';
import get from 'lodash/get';

function useCustomButtonPayload({
    activeSubsectionId,
    cardIdentifierKeys,
    displayType,
    illusSearchEnabled = false,
    listIndex = null,
    pageSeqNumber,
    presentationType,
    questionId,
    sectionalData,
    sectionIndex,
    validations = []
}){
    let currentSectionAnswerList = sectionalData[activeSubsectionId][sectionIndex];

    const { answersArray, errorsArray } = currentSectionAnswerList
    const dependantSelectiveFieldIds = get(validations, 'dependant_selective_field_ids', []);

    if(presentationType === 'illustration_search'){
        currentSectionAnswerList = answersArray.filter((eachAns) => dependantSelectiveFieldIds.includes(eachAns.question_id))
        const illusSearchFieldsAns = currentSectionAnswerList.filter((eachAns) => (eachAns.answer || eachAns.answer === 0))
        const illusSearchFieldsErr = errorsArray.filter((eachErrorObj) => dependantSelectiveFieldIds.includes(eachErrorObj.question_id))
        illusSearchEnabled = (illusSearchFieldsAns?.length === dependantSelectiveFieldIds?.length) && !illusSearchFieldsErr?.length
    }

    if(displayType === 'sub_list_submit'){
        currentSectionAnswerList = currentSectionAnswerList?.answersArray?.filter((eachAns) => dependantSelectiveFieldIds.includes(eachAns.question_id))
    }

    let showIllustrationPDF = currentSectionAnswerList?.show_illustration_pdf
    let fetchedIllustrations = currentSectionAnswerList?.fetched_illustrations
    if(presentationType === 'fetch_illustration_pdf'){
        showIllustrationPDF = currentSectionAnswerList?.show_illustration_pdf
        fetchedIllustrations = currentSectionAnswerList?.fetched_illustrations
    }

    if(presentationType === 'reged_validation'){
        currentSectionAnswerList = answersArray
    }

    if(Array.isArray(currentSectionAnswerList)){
        currentSectionAnswerList = currentSectionAnswerList?.map((eachResp) => {
            return {
                question_id: eachResp.question_id,
                answer: eachResp.answer,
            }
        })

        if(!displayType) currentSectionAnswerList.push({ question_id: questionId, answer: '' })

        currentSectionAnswerList = currentSectionAnswerList?.filter((obj1, i, arr) =>
            arr.findIndex(obj2 => (obj2.question_id === obj1.question_id)) === i
        )
    }

    const queryParams = {
        questions: currentSectionAnswerList,
        page_sequence_number: pageSeqNumber,
        ...(!displayType && {reflexive_question_flag: true}),
        ...(presentationType && {[presentationType]: true}),
        ...(displayType && {[displayType]: true}),
        ...((listIndex !== null && listIndex >=0) && { current_list_index: listIndex, current_list_answer_index: listIndex }),
        ...(presentationType === 'fetch_illustration_pdf' && cardIdentifierKeys),
    }

    return {
        queryParams,
        illusSearchEnabled,
        showIllustrationPDF,
        fetchedIllustrations
    }
}

export default useCustomButtonPayload