import React from 'react';
import styled from 'styled-components';

import { Button as ButtonComponent } from 'crux';


const ButtonWrapper = styled.div`
    display: flex;
    margin: ${({ displayType }) => displayType === 'sub_list_submit' ? '10px 0% 10px 18%' : '10px'};
    margin: ${({ presentationType }) => presentationType === 'illustration_search' ? '10px 4% 25px 18%' : '10px'};
    margin: ${({ presentationType }) => presentationType === 'reged_validation' ? '10px 4% 25px 18%' : '10px'};
    ${({ alignment }) => alignment === 'left' && `
        justify-content: flex-start;
    `}
    ${({ alignment }) => alignment === 'center' && `
        justify-content: center;
        align-content: center;
    `}
    ${({ alignment }) => alignment === 'right' && `
        justify-content: flex-end;
    `}
`

const CustomButton = ({
    buttonClickHandler,
    buttonStyles,
    isDisabled,
    presentationType,
    question,
}) => {

    const {
        question_id: questionId,
        question_text: questionText,
        display_type: displayType,
    } = question;

    const ButtonWrapperProps = {
        alignment: buttonStyles.align,
        presentationType,
        displayType
    }

    return (
        <ButtonWrapper className={`${presentationType}_button-container`} id={`${presentationType}_button-container`} {...ButtonWrapperProps} >
            <ButtonComponent
                id={ `${presentationType}_${questionId}-button` }
                label={ questionText }
                handleButtonClick={buttonClickHandler}
                disabled={ isDisabled }
                {...buttonStyles}
            />
        </ButtonWrapper>
    );
}

export default CustomButton;